.body {
  min-height: 60px;
  align-items: center;
  background-color: azure;
}

.bodyPart {
  width: 100%;
  border-bottom: 1px solid var(--color-gray-20);
}

.bodyPartFirst {
  align-items: center;
  background-color: white;
  padding: 6px 20px;
  display: flex;
  min-height: 60px;
}

.bodyPartSecond {
  background-color: var(--color-horizon);
}

.bar {
  display: flex;
  height: var(--header-size);
  border-bottom: 1px solid var(--color-gray-20);
  align-items: center;
  box-sizing: border-box;
  background: var(--color-horizon);
}

.tab {
  padding: 0 20px;
  height: var(--header-size);
  line-height: var(--header-size);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 0.2s ease;
  font-size: 16px;
  font-weight: 500;
}

.tab + .tab {
  margin-left: 10px;
}

.tab:hover,
.active {
  border-bottom: 3px solid var(--color-int-blue);
  color: var(--color-int-blue);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 5px;
}

.resetFilter {
  white-space: nowrap;
}
