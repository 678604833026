.BookingResponseStatus__Ok {
  display: flex;
  font-size: 24px;
  color: var(--color-int-green);
}

.BookingResponseStatus__Warning {
  display: flex;
  font-size: 24px;
  color: var(--color-int-blue);
}

.BookingResponseStatus__Error {
  display: flex;
  font-size: 24px;
  color: var(--color-int-red);
}
