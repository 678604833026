.DateInput {
  display: flex;
  margin: 0;
  padding: 0;
  margin-top: var(--baseline-2);
}

.DateInput__Icon {
  margin-top: 2px;
  margin-left: -2px;
  font-size: 34px;
  min-width: 50px;
  max-width: 50px;
}

.DateInput--date {
  margin-top: initial;
}

.DateInput__Input {
  width: 100%;
}

.DateInput__Input--scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.DateInput__Input input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.DateInputFlyout {
  position: absolute;
  top: 0;
  left: 50px;
  z-index: 500;
  width: 300px;
  background-color: var(--color-white);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.DateInputFlyout__Wrapper {
  position: relative;
  margin-bottom: 20px;
}

.DateInputFlyout .BookDetailDatePicker {
  padding: 16px;
}

.DateInputFlyout .DateInput__Input {
  padding-top: 0;
}

.DateInputFlyout.ArrowBox.ArrowUp:before {
  left: calc(50% - 17px);
}

.DateInput__Input button.rdp-day {
  border-radius: unset;
}

.DateInput__Input button.rdp-day_selected {
  background-color: var(--color-daypicker-today);
}

.DateInput__Input button.rdp-day_selected.rdp-day_today {
  color: var(--color-white);
}