.MyBookingsFormSubmit__ButtonGroup {
  width: 100%;
  display: flex;
}

.MyBookingsFormSubmit__Button {
  width: 50%;
}

.MyBookingsFormSubmit__Button:first-child {
  margin-right: 20px;
}

@media screen and (max-width: 600px) {
  .MyBookingsFormSubmit__ButtonGroup {
    flex-wrap: wrap;
  }

  .MyBookingsFormSubmit__Button {
    width: 100%;
  }

  .MyBookingsFormSubmit__ButtonGroup .MyBookingsFormSubmit__Button:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
