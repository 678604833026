.Browser {
  width: 100%;
  height: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  position: fixed;
  z-index: 10000000;
}

.Browser__Content {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
  text-align: center;
}

.BrowserContent__Icon {
  font-size: 44px;
  float: left;
}

.BrowserContent__Text {
  margin-left: 55px;
  text-align: left;
  margin-bottom: 20px;
}

.BrowserContent__CloseButton {
  float: right;
}

.BrowserContentCloseButton__Icon {
  font-size: 40px;
}

@media screen and (min-width: 500px) and (max-width: 700px) {
  .Browser__Content {
    width: 400px;
    height: 160px;
    margin: -80px 0 0 -200px;
  }
}

@media screen and (min-width: 701px) {
  .Browser__Content {
    width: 600px;
    height: 120px;
    margin: -60px 0 0 -300px;
  }

  .BrowserContent__Text {
    margin-left: 80px;
    margin-right: 50px;
  }

  .BrowserContent__Close {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .BrowserContent__CloseButton {
    display: none;
  }
}
