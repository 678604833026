a, a.link, button.link {
  line-height: 24px;
  letter-spacing: -.1px;
  font-size: 16px;
  position: relative;
  transition: border-bottom 200ms var(--button-easing), color 200ms var(--button-easing);
  outline: none;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-link);
}

a:hover, a:focus, a.link:hover, a.link:focus, button.link:hover, button.link:focus {
  border-bottom: 1px solid var(--color-link--active);
  color: var(--color-link--active);
}

a.bold-link {
  font-weight: bold;
}