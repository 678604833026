.Dialog__Overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Dialog__Container {
  background-color: var(--color-white);
  height: auto;
  position: relative;
}
