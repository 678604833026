.PersonPicker__Error {
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  z-index: 101;
  transition: 150ms all var(--standard-easing);
}

.PersonPicker--invalid .PersonPicker__Error {
  max-height: 50px;
  margin-top: 6px;
  opacity: 1;
  color: var(--color-input-invalid-text);
}
