.container {
  display: flex;
  background-color: var(--color-horizon);
  border-bottom: 1px solid var(--color-gray-20);
}

.panel {
  display: flex;
  flex-direction: column;
}

.button {
  min-width: 360px;
  margin: 20px;
}

.empty {
  background-color: var(--color-int-orange);
  border-bottom: 1px solid var(--color-int-orange);
}
