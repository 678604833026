.RecurrenceEnd {
  margin: 20px 20px 0;
}

.RecurrenceEnd__Table {
  border-collapse: collapse;
  border-spacing: 0;
}

.RecurrenceEnd__Table td:not(:last-child) {
  padding-right: 5px;
}

.BookFilter .RecurrenceEnd__Table {
  width: 100%;
}

.RecurrenceEnd__Radio label {
  white-space: nowrap;
}

.RecurrenceEnd__DateInput {
  margin-top: var(--baseline-2);
  width: 100%;
  max-width: 410px;
}

.BookFilter .RecurrenceEnd__DateInput {
  max-width: 100%;
}

.RecurrenceEnd__ValidationError {
  margin-top: var(--baseline);
}
