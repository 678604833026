.RecurrenceSwitch__Boxes {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 20px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RecurrenceSwitch__Boxes .SelectBox:not(.SelectBox--selected) {
  background-color: var(--color-horizon);
}

.RecurrenceSwitch__Boxes .SelectBox:nth-last-child(2),
.RecurrenceSwitch__Boxes .SelectBox:last-child {
  margin-bottom: 0;
}

.RecurrenceSwitchBox {
  padding: 12px 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
