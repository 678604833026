.item {
  color: var(--color-black);
  min-width: 200px;
  background-color: var(--color-white);
  padding: 15px;
  margin-bottom: 10px;
  border-bottom: var(--color-gray-20) 1px solid;
}

.header {
  font-weight: 700;
}

.dateText {
  font-weight: 500;
  font-size: 13px;
}