.EquipmentNameAndType__Equipment {
  font-weight: 500;
  margin-right: 10px;
}

.EquipmentNameAndType__EquipmentName {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.EquipmentNameAndType__EquipmentBuilding {
  white-space: nowrap;
}
