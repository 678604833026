.ChangeDetector {
  display: flex;
}

.ChangeDetector__Children {
  flex: auto;
  width: 100%;
}

.ChangeDetector__Changed {
  width: 100px;
  min-width: 100px;
  color: var(--color-int-blue);
  text-align: right;
  margin-bottom: 8px;
}

.ChangeDetector__Changed span {
  cursor: help;
}

.ChangeDetector__Changed--invalid {
  color: var(--color-int-red);
}
