.bodyResults {
    border-top: 1px solid var(--color-gray-20);
}

.bodyBooking {
    position: relative;
    height: 80px !important;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    border-left: 1px solid var(--color-gray-20);
    align-content: center;
    align-items: center;
    padding: 10px 20px;
}

.bodyBooking:before {
    position: absolute;
    left: -7px;
    top: 18px;
    content: "";
    height: 13px;
    width: 13px;
    border: 1px solid black;
    /* border: 1px solid var(--colors-room-standup); */
    background-color: white;
    border-radius: 50%;
    display: inline-block;
}

.bodyBooking.unspecified:before {
    border-color: var(--color-gray-20);
}

.bodyBooking.meeting:before {
    border-color: var(--colors-room-meeting);
}

.bodyBooking.standup:before {
    border-color: var(--colors-room-standup);
}

.bodyBooking.conference:before {
    border-color: var(--colors-room-conference);
}

.bodyBooking.project:before {
    border-color: var(--colors-room-project);
}

.bodyBooking.hybridmeeting:before {
  border-color: var(--colors-room-hybridmeeting);
}

.bodyBooking.training:before {
    border-color: var(--colors-room-training);
}

.bodyBooking.workshop:before {
    border-color: var(--colors-room-workshop);
}

.bodyBooking.auditorium:before {
    border-color: var(--colors-room-auditorium);
}

.journalText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow:hidden;
    display:block;
    width:450px;
}

.call {
    line-height: 22px;
    display: inline-flex;
    font-size: 22px;
    height: 22px;
    margin: 19px 0;
}

@media (max-width: 399px) {
    .journalText {
        width:175px;
    }
}

@media (min-width: 400px) {
    .journalText {
        width:275px;
    }
}

@media all and (max-width: 600px) {
    .textCall {
        display: none;
    }
    .columnBookButton button {
        min-width: 100px;
    }
}

@media(min-width:601px){
    .journalText {
        width:450px;
    }
}

@media all and (max-width: 900px) {
    .bodyBooking {
        display: grid;
    }
    .columnBookButton {
        grid-column: 2;
        grid-row: 1 / 3;
    }
    .firstColumn {
        grid-column: 1;
        grid-row: 1;
    }
    .secondColumn {
        grid-column: 1;
        grid-row: 2;
    }
    .thirdColumn {
        grid-column: 2;
        grid-row: 1 / 3;
    }
}

.time {
    font-weight: bold;
}

@media (min-width: 901px) {
    .firstColumn {
        line-height: 30px;
    }
    .secondColumn {
        line-height: 60px;
    }
    .thirdColumn {
        line-height: 60px;
        width: 160px;
    }
}
