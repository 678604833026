.MaintenanceMessage {
  min-height: 60px;
  display: flex;
  justify-content: center;
  z-index: 99991;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
}

.MaintenanceMessage--info {
  background-color: var(--color-sc-blue);
  color: var(--color-white);
}

.MaintenanceMessage--maintenance {
  background-color: var(--color-int-orange);
  color: var(--color-white);
}

.MaintenanceMessage.MaintenanceMessage--info a {
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white);
}

.MaintenanceMessage__Content {
  flex: 1;
  display: flex;
  align-items: center;
}

.MaintenanceMessage__Text {
  flex: 1;
}

.MaintenanceMessage__Icon {
  width: 70px;
  font-size: 48px;
  display: flex;
  justify-content: center;
  height: 100%;
}

.MaintenanceMessage__Close {
  width: 70px;
  font-size: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 7px;
}

.MaintenanceMessage__Close i {
  cursor: pointer;
}
