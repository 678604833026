.bar {
  display: flex;
  height: var(--header-size);
  border-bottom: 1px solid var(--color-gray-20);
  align-items: center;
  box-sizing: border-box;
}

.tab {
  padding: 0 20px;
  height: var(--header-size);
  line-height: var(--header-size);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 0.2s ease;
  font-size: 16px;
  font-weight: 500;
}

.tab + .tab {
  margin-left: 10px;
}

.tab:hover,
.active {
  border-bottom: 3px solid var(--color-int-blue);
  color: var(--color-int-blue);
}


.disabled {
  cursor: default;
  color: grey;
}

.disabled:hover {
  border-bottom: none;
  color: grey;
}