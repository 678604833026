.BookingCollectionDeleteDesktop__Sidebar {
  width: 400px;
  min-width: 400px;
  height: 100%;
}

.BookingCollectionDeleteDesktop__Content {
  display: flex;
  height: calc(100vh - 60px);
}

.BookingCollectionDeleteDesktop__Results {
  width: 100%;
}
