*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}

html,
body {
  font-family: 'TheSans', sans-serif;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  background-color: #fff;
  text-rendering: optimizeLegibility;
  color: #333;
  box-sizing: border-box;
}

html.no-scroll > body {
  overflow: hidden;
}
