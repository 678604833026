.SearchFormFilter {
  background-color: var(--color-white);
  padding-bottom: 60px;
}

.SearchFormFilter .SelectBox:not(.SelectBox--selected) {
  background-color: var(--color-horizon);
}

.SearchFormFilter .RoomFilter__Equipment .RoomFilter__ShowMore {
  margin-bottom: 20px;
}
