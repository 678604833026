.TimeSection {
  display: flex;
  margin-top: 0;
  width: 100%;
  max-width: 250px;
}

.TimeSection__Icon {
  margin-top: 2px;
  font-size: 34px;
  max-width: 46px;
  min-width: 46px;
}

.TimeSection__Input {
  width: 100%;
}
