.RecurrenceExceptionModalEmptyState {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RecurrenceExceptionModalEmptyState .EmptyState__Wrapper {
  color: var(--color-int-orange);
}

@media only screen and (min-width: 900px) {
  .RecurrenceExceptionModalEmptyState {
    height: calc(100% - 60px);
    margin-top: -10px;
  }
}

.RecurrenceExceptionModalEmptyState__Text {
  margin-top: 4px;
}

.RecurrenceExceptionModalEmptyState__Button {
  margin-top: var(--baseline-2);
}
