.RoomNameAndType__Room {
  font-weight: 500;
  margin-right: 10px;
}

.RoomNameAndType__RoomName {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.RoomNameAndType__RoomBuilding {
  white-space: nowrap;
  color: var(--color-sc-gray-light);
}
