.Notification {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: var(--header-size);
  position: fixed;
  top: 0;
  left: 0;
  transition: visibility 0.5s, opacity 0.5s var(--standard-easing),
    height 0.3s var(--standard-easing);
  z-index: 99999;
}

.Notification--hidden {
  visibility: hidden;
  opacity: 0;
}

.Notification--expanded {
  transition: visibility 0.5s, opacity 0.5s var(--standard-easing),
    height 0.3s var(--standard-easing);
}

.Notification--confirmation {
  color: white;
  background-color: var(--color-int-green);
}

.Notification--warning {
  color: white;
  background-color: var(--color-int-orange);
}

.Notification--info {
  color: white;
  background-color: var(--color-int-blue);
}

.Notification--error {
  color: white;
  background-color: var(--color-int-red);
}

/* Notification Icon (on the left) */
.Notification__Icon {
  width: 60px;
  overflow: hidden;
}

.Notification__Confirmation-Icon {
  /* Consider white-space of icon on the left */
  padding-left: calc(var(--baseline-2) - 2px);
  font-size: 46px;
  line-height: var(--header-size);
}

.Notification__Error-Icon {
  margin-top: 25px;
  padding-left: 6px;
  font-size: 56px;
  transition: margin-top 0.3s var(--standard-easing);
}

.Notification__Error-Icon--spinned {
  margin-top: 26px;
  transform: rotate(-7deg);
}

.Notification--expanded .Notification__Error-Icon {
  margin-top: 6px;
}

/* Notification Message (in the middle) */
.Notification__Message {
  width: calc(100% - 110px);
  padding: var(--baseline);
  padding-bottom: calc(var(--baseline) + 4px);
}

.Notification__Message--autoclose {
  width: calc(100% - 60px);
  padding-right: 20px;
}

.Notification__Message--expandable {
  cursor: pointer;
}

.Notification__Message--expandable:focus {
  outline: none;
}

.Notification__Title {
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.1px;
  font-size: 16px;
}

.Notification__Text {
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0;
}

.Notification__Text--cropped {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Notification close icon (on the right) */
.Notification__Close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50px;
  /* Consider white-space of icon on the right */
  padding-right: calc(var(--baseline-2) - 6px);
}

.Notification__Close-Icon {
  font-size: 34px;
}

.Notification__Close-Link,
.Notification__Close-Link:hover,
.Notification__Close-Link:focus {
  color: white;
  border-bottom: none;
}
