.SearchFormInput {
  display: flex;
  margin: 0;
  outline: none;
  cursor: pointer;
}

.SearchFormInput--form {
  padding: var(--baseline-2) 20px;
  background-color: var(--color-horizon);
  border-bottom: 1px solid var(--color-gray-20);
}

.SearchFormInput--centered {
  width: 500px;
  display: flex;
  margin: auto;
}

.SearchFormInput--date,
.SearchFormInput--time {
  padding: 0;
}

.SearchFormInput--time {
  margin-top: var(--baseline-2);
}

.SearchFormInput__Input {
  width: 100%;
}

.SearchFormInput__Input input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.input-field input[type='text'].SearchFormInput__Input--error {
  border-color: var(--color-input-invalid-form);
}

.input-field input[type='text']:focus.SearchFormInput__Input--readonly:focus {
  border-color: var(--color-input-normal-form);
}

.SearchFormInput__Icon {
  margin-top: 2px;
  margin-left: -2px;
  font-size: 34px;
  min-width: 50px;
  max-width: 50px;
}

.SearchFormInput__Icon--white {
  color: var(--color-white);
}
