.ChangeRequestFilter {
padding-top: 20px;
}

.ChangeRequestFilter.horizontal {
display: grid;
grid-template-columns: repeat(4, 1fr);
padding: 20px 0;
}

.ChangeRequestFilter__Section {
display: flex;
margin: var(--baseline-2) 20px;
max-width: 500px;
}

.ChangeRequestFilter__ShowMore {
padding: 0 20px;
text-align: right;
font-weight: 500;
}

.ChangeRequestFilter__Title {
padding: 0 20px;
font-weight: 500;
text-align: left;
}

.ChangeRequestFilter__Icon {
margin-top: 2px;
margin-left: -2px;
font-size: 34px;
width: 60px;
}

.ChangeRequestFilter__Content {
width: 100%;
}

.ChangeRequestFilter__Content > .SelectBox {
-webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);
-moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);
box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.ChangeRequestFilter__Divider {
border: none;
border-top: 1px solid var(--color-gray-20);
margin: 20px 0;
}

.ChangeRequestFilter__Spacer {
height: 60px;
}

.TypeBoxes {
width: 100%;
display: flex;
align-items: stretch;
flex-wrap: wrap;
}

.TypeBox {
padding: 12px 20px;
text-align: left;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.select__button {
min-width: 220px !important;
}

.ChangeRequestFilter__Section.Floor__Filter {
margin-bottom: 8px;
}

.ChangeRequestFilter__Section.Sector__Filter {
margin-top: 0px;
}

.ChangeRequestFilter__Content button.select__button {
background-color: white;
height: 48px;
}

.ChangeRequestFilter__Content button.select__button .select__placeholder {
border: none;
box-shadow: none;
}

.ChangeRequestFilter__Content .select .select__dropdown .dropdown-item {
border: none;
}

.mobile .ChangeRequestFilter__Content button.select__button {
background-color: var(--color-horizon);
}

#ChangeRequestFilter_Input {
    height: 48px;
}
