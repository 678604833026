.DropDown,
.DropDown__Container {
  width: 100%;
}

.DropDown__Container .DropDown__control {
  border-radius: 0px;
  min-height: 40px;
}

.DropDown__Container .DropDown__control--is-focused {
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.1);
}

.DropDown__Container .DropDown__control:hover {
  cursor: text;
  box-shadow: none;
}

.DropDown__Container .DropDown__value-container {
  padding-left: 16px;
}

.DropDown__Container .DropDown__placeholder,
.DropDown__Container .DropDown__single-value {
  color: var(--color-input-placeholder);
  margin-top: 1px;
}

.DropDown__Container .DropDown__menu-notice--no-options,
.DropDown__Container .DropDown__menu-notice--loading {
  display: none;
}

/* Dropdown & Clear Indicator */
.DropDown__Container .DropDown__clear-indicator,
.DropDown__Container .DropDown__dropdown-indicator,
.DropDown__Container .DropDown__control--is-focused .DropDown__dropdown-indicator {
  color: var(--color-gray-80);
}

.DropDown__Container .DropDown__dropdown-indicator,
.DropDown__Container .DropDown__indicator-separator {
  display: none;
}
.DropDown__Container .DropDown__clear-indicator {
  cursor: pointer;
}

.DropDown__Container .DropDown__clear-indicator:hover,
.DropDown__Container .DropDown__dropdown-indicator:hover {
  color: var(--color-black);
}

.DropDown__Container .DropDown__loading-indicator > span {
  background-color: var(--color-gray-80);
}

/* Menu */
.DropDown__Container .DropDown__menu {
  margin-bottom: 0;
  margin-top: 0;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.1);
  background: var(--color-white);
  color: var(--color-gray);
  z-index: 999;
}

.DropDown__Container .DropDown__menu-list {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 400px;
}

/* Option */
.DropDown__Container .DropDown__option {
  border: 1px solid var(--color-input-normal-form);
  border-top: 0;
  padding-left: 16px;
  background-color: var(--color-white);
  cursor: pointer;
  height: 40px;
}

.DropDown__Container .DropDown__option:focus {
  outline: none;
}

.DropDown__Container .DropDown__option:hover,
.DropDown__Container .DropDown__option:active,
.DropDown__Container .DropDown__option--is-focused,
.DropDown__Container .DropDown__option--is-focused.DropDown__option--is-selected {
  color: var(--color-white);
  border-color: var(--color-int-blue);
  background-color: var(--color-int-blue);
}

.DropDown__Container .DropDown__option--is-selected {
  color: var(--color-int-blue);
  background-color: var(--color-white);
}

.DropDown__Container .DropDown__option--is-selected::before {
  float: right;
  font-family: sdx-icons;
  content: '\E00B';
}

/* Tags (isMulti) */
.DropDown__Container .DropDown__value-container.DropDown__value-container--is-multi {
  padding-left: 14px;
}

.DropDown__Container .DropDown__value-container--is-multi.DropDown__value-container--has-value {
  padding-left: 8px;
}

.DropDown__Container .DropDown__multi-value {
  background-color: transparent;
  margin: 0;
  cursor: pointer;
}

.DropDown__Container .DropDown__multi-value:hover {
  background-color: var(--color-aluminum);
}

.DropDown__Container .DropDown__multi-value__label {
  font-size: 16px;
  border-radius: 0;
  padding: 0;
  padding-left: 8px;
}

.DropDown__Container .DropDown__multi-value__remove {
  font-size: 14px;
  padding-left: 2px;
  color: var(--color-gray);
  transition: all 80ms ease-in;
}

.DropDown__Container .DropDown__multi-value__remove:hover {
  background-color: transparent;
  color: var(--color-gray);
}

.DropDown__Container .DropDown__multi-value__remove .icon {
  transition: all 80ms ease-in;
}

.DropDown__Container .DropDown__multi-value__remove:hover .icon {
  font-weight: 600;
}
