.Language__Selection {
  height: 160px;
  width: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -80px 0 0 -50px;
  text-align: center;
}

.Language__SelectionItem {
}

.Language__SelectionItem--selected {
  color: var(--color-int-blue);
}

.Language__SelectionLine {
  border-color: var(--color-horizon);
  border-width: 1px;
  border-style: solid;
}
