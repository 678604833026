.table {
  display: table;
  width: 100%;
}

.table .table__wrapper {
  display: table;
  width: 100%;
}

.table table {
  display: table;
  width: 100%;
  color: #333;
  border-collapse: collapse;
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
}

.table table:not(:last-child) {
  margin-bottom: 16px;
}

.table th,
.table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
}

.table th:first-child,
.table td:first-child {
  padding-left: 16px;
}

.table th:last-child,
.table td:last-child {
  padding-right: 16px;
}

.table thead {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  font-weight: 500;
}

.table thead tr {
  border-bottom: 1px solid #adadad;
  text-align: left;
}

.table thead th {
  padding-top: 25px;
  padding-bottom: 23px;
}

.table thead th[data-type] {
  cursor: pointer;
}

.table thead th .arrow-icon {
  position: relative;
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  pointer-events: none;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: visibility 200ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.table thead th .arrow-icon::before,
.table thead th .arrow-icon::after {
  position: absolute;
  top: 50%;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 2px;
  background: #015;
  width: 10px;
  height: 2px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
}

.table thead th .arrow-icon::before {
  left: 0;
}

.table thead th .arrow-icon::after {
  left: 6px;
}

.table thead th.js-ascending .arrow-icon {
  visibility: visible;
  opacity: 1;
}

.table thead th.js-ascending .arrow-icon::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.table thead th.js-ascending .arrow-icon::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.table thead th.js-descending .arrow-icon {
  visibility: visible;
  opacity: 1;
}

.table thead th.js-descending .arrow-icon::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.table thead th.js-descending .arrow-icon::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.table tbody {
  line-height: 24px;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table tbody tr {
  text-align: left;
}

.table tbody tr:not(:last-child) {
  border-bottom: 1px solid #adadad;
}

.table tbody td {
  padding-top: 20px;
  padding-bottom: 17px;
}

.table .button {
  margin: 0 16px;
}

.table--responsive {
  display: block;
}

.table--responsive::after {
  content: '';
  display: block;
  clear: both;
}

.table--responsive:not(:last-child) {
  margin-bottom: 16px;
}

.table--responsive .table__wrapper {
  display: block;
  width: 100%;
  min-height: 0;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table--responsive .table__wrapper:not(:last-child) {
  margin-bottom: 16px;
}

.table--reflow {
  display: block;
}

.table--reflow .table__wrapper {
  display: block;
  width: 100%;
}

.table--reflow table {
  display: block;
  position: relative;
  width: 100%;
}

.table--reflow td:first-child,
.table--reflow th:first-child {
  padding-left: 8px;
}

.table--reflow td:last-child,
.table--reflow th:last-child {
  padding-right: 8px;
}

.table--reflow thead {
  display: block;
  float: left;
}

.table--reflow thead tr {
  display: block;
  border-bottom: 0;
}

.table--reflow thead tr th {
  padding-left: 16px;
}

.table--reflow thead tr th::after {
  content: '\00a0';
}

.table--reflow tbody {
  display: block;
  width: auto;
  white-space: nowrap;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table--reflow tbody tr {
  display: table-cell;
  border-bottom: 0;
}

.table--reflow tbody tr:last-child td {
  padding-right: 16px;
}

.table--reflow thead tr,
.table--reflow tbody tr,
.table--reflow tfoot tr {
  border-bottom: 0 !important;
}

.table--reflow td:empty::after {
  content: '\00a0';
}

.table--reflow tr td,
.table--reflow tr th {
  display: block;
  padding-top: 20px;
  padding-bottom: 17px;
  border-bottom: 1px solid #adadad;
}

.table--reflow tr td:last-child,
.table--reflow tr th:last-child {
  border-bottom: 0;
}

.table--shadow {
  padding: 19px 0 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.table--highlight thead {
  background: #f9fafc;
}

.table--no-border .table__title {
  padding-left: 0;
  padding-right: 0;
}

.table--no-border table th:first-child,
.table--no-border table td:first-child {
  padding-left: 0;
}

.table--no-border table th:last-child,
.table--no-border table td:last-child {
  padding-right: 0;
}
