.EditResource__Divider {
  border: 0;
  background-color: var(--color-gray-20);;
  height: 1px;
}

.EditResource__Loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditResource__Form {
  width: 700px;
  margin: 0 auto;
  padding-top: 20px;
}
