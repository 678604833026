.TrayIcons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.TrayIcons .Tray-Item {
  height: 30px;
  width: 30px;
}

.TrayIcons .Tray-Item.Tray-Item--small {
  width: 24px;
}
