.DaysSelectBoxes,
.WeekSelectBoxes {
  display: flex;
}

.WeekSelectBoxes {
  margin-bottom: 8px;
}

.DaysSelectBox,
.WeekSelectBox {
  padding: 12px 10px;
  text-align: center;
}

.DaysSelectBox:not(:last-child),
.WeekSelectBox:not(:last-child) {
  margin-right: 8px;
}

.DaysSelectBoxes .SelectBox:not(.SelectBox--selected),
.WeekSelectBoxes .SelectBox:not(.SelectBox--selected) {
  background-color: var(--color-horizon);
}

.RecurrenceWeekly.RecurrenceWeekly--inverted,
.RecurrenceWeekly.RecurrenceMonthly--inverted {
  margin-top: 9px;
}

.RecurrenceWeekly--inverted .DaysSelectBoxes .SelectBox:not(.SelectBox--selected),
.RecurrenceMonthly--inverted .DaysSelectBoxes .SelectBox:not(.SelectBox--selected),
.RecurrenceMonthly--inverted .WeekSelectBoxes .SelectBox:not(.SelectBox--selected) {
  background-color: var(--color-white);
}

.RecurrenceWeekly--inverted .SelectBox,
.RecurrenceMonthly--inverted .SelectBox {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.DaysSelectBoxes .SelectBox {
  width: 20%;
}

.WeekSelectBoxes .SelectBox {
  width: 25%;
}
