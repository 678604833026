.SearchFormDataQuality {
  background-color: var(--color-horizon);
  padding: 10px 0;
  border-bottom: 1px solid var(--color-gray-20);
}

.SearchFormDataQuality .DataQualityInformation {
  justify-content: flex-start;
  margin-left: 20px;
  margin-right: 20px;
}

.SearchFormDataQuality .DataQualityInformation__Text {
  margin-top: 2px;
  line-height: 20px;
}
