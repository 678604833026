.RoomCard {
  position: relative;
  height: 100%;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--baseline-2) 20px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  font-weight: 400;
  flex-wrap: wrap;
  outline: none;
  position: relative;
  overflow: hidden;
}

.RoomCard:after {
  height: calc(100% - var(--baseline) * 2);
  content: '';
  width: 5px;
  position: absolute;
  left: 0;
  top: var(--baseline);
}

.RoomCard--unspecified.RoomCard:after {
  background-color: var(--color-gray-20);
}

.RoomCard--meeting.RoomCard:after {
  background-color: var(--colors-room-meeting);
}

.RoomCard--standup.RoomCard:after {
  background-color: var(--colors-room-standup);
}

.RoomCard--conference.RoomCard:after {
  background-color: var(--colors-room-conference);
}

.RoomCard--project.RoomCard:after {
  background-color: var(--colors-room-project);
}

.RoomCard--hybridmeeting.RoomCard:after {
  background-color: var(--colors-room-hybridmeeting);
}

.RoomCard--training.RoomCard:after {
  background-color: var(--colors-room-training);
}

.RoomCard--workshop.RoomCard:after {
  background-color: var(--colors-room-workshop);
}

.RoomCard--auditorium.RoomCard:after {
  background-color: var(--colors-room-auditorium);
}

/* RoomType */
.RoomType--unspecified {
  color: var(--color-gray-20);
}

.RoomType--meeting {
  color: var(--colors-room-meeting);
}

.RoomType--standup {
  color: var(--colors-room-standup);
}

.RoomType--conference {
  color: var(--colors-room-conference);
}

.RoomType--project {
  color: var(--colors-room-project);
}

.RoomType--hybridmeeting {
  color: var(--colors-room-hybridmeeting);
}

.RoomType--training {
  color: var(--colors-room-training);
}

.RoomType--workshop {
  color: var(--colors-room-workshop);
}

.RoomType--auditorium {
  color: var(--colors-room-auditorium);
}
