.modal {
  margin-top: 10px;
  margin-bottom: 14px;
  width: 130px;
  height: 130px;
}

.dialog {
  margin-top: -14px;
  margin-left: -14px;
}
