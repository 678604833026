.DropDown__option--region,
.DropDown__option--building,
.DropDown__option--floor,
.DropDown__option--room {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.DropDown__option--region::before,
.DropDown__option--building::before,
.DropDown__option--floor::before,
.DropDown__option--sector::before,
.DropDown__option--room::before {
  float: right;
  font-family: sdx-icons;
  margin-right: -2px;
  color: var(--color-gray-80);
}

.DropDown__option:hover .DropDown__option--region::before,
.DropDown__option:hover .DropDown__option--building::before,
.DropDown__option:hover .DropDown__option--floor::before,
.DropDown__option:hover .DropDown__option--sector::before,
.DropDown__option:hover .DropDown__option--room::before,
.DropDown__option:hover .DropDown__option--light,
.DropDown__option--is-focused .DropDown__option--region::before,
.DropDown__option--is-focused .DropDown__option--building::before,
.DropDown__option--is-focused .DropDown__option--floor::before,
.DropDown__option--is-focused .DropDown__option--sector::before,
.DropDown__option--is-focused .DropDown__option--room::before,
.DropDown__option--is-focused .DropDown__option--light {
  color: var(--color-white);
}

.DropDown__option--room::before {
  content: '\E0DF';
}

.DropDown__option--building::before {
  content: '\E097';
}

.DropDown__option--floor::before {
  content: '\E124'; 
}

.DropDown__option--sector::before {
  content: '\E06D';
}

.DropDown__option--region::before {
  content: '\E0D8';
}

.DropDown__option--light {
  color: var(--color-gray-40);
}
