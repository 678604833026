.PhotoAdmin__RoomPicker {
  margin-top: 60px;
  padding-top: 100px;
  padding-bottom: 40px;
  width: 700px;
  margin: 0 auto;
}

.PhotoAdmin__Divider {
  border: 0;
  background-color: var(--color-gray-20);
  height: 1px;
  margin: 0;
}

.PhotoAdmin__Items {
  width: 700px;
  margin: 0 auto;
  padding-top: 40px;
}

.PhotoAdminItem {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.PhotoAdmin__Info,
.PhotoAdmin__MaxSize {
  display: flex;
  color: var(--color-sc-gray-mid);
  margin-top: 20px;
}

.PhotoAdmin__Info i,
.PhotoAdmin__MaxSize i {
  width: 28px;
  min-width: 28px;
  margin-top: 3px;
}

.PhotoAdminItem__Image {
  width: 350px;
}

.PhotoAdminItem__Image img {
  max-width: 100%;
}

.PhotoAdminItem__Action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.PhotoAdmin__Upload {
  padding: 40px 0;
  width: 700px;
  margin: 0 auto;
}

.PhotoAdminUpload {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.PhotoAdminUpload__Input {
  display: none !important;
}
