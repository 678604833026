.OwnerFormProfile {
  margin-top: var(--baseline-4);
}

.OwnerFormProfile__Content {
  margin-left: 36px;
}

.OwnerFormProfile__Description {
  width: calc(100% - 100px);
  margin-top: 6px;
  margin-bottom: var(--baseline);
  color: var(--color-sc-gray-mid);
  display: flex;
  align-items: center;
}

.OwnerFormProfileDescription__Icon {
  margin-right: 10px;
}

.OwnerFormReason {
  margin-top: 40px;
}

.OwnerForm__ValidationError {
  color: var(--color-input-invalid-text);
  margin-top: -8px;
  margin-bottom: var(--baseline-2);
}

.OwnerForm__Submit {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 100px);
  margin-top: var(--baseline-4);
  margin-bottom: 60px;
}

.OwnerForm__Owners--readonly .ChangeDetector__Children {
  flex-direction: column;
}

.OwnerForm__Owners--readonly .FormFieldReadOnly__Value {
  margin-top: var(--baseline);
}

.OwnerFormProfile__ProfileType .ChangeDetector__Changed {
  margin-bottom: 0;
}
