.CompactSwitcherWrapper {
  display: flex;
  align-items: center;
  color: var(--color-black);
  font-weight: 500;
  height: 100%;
  background-color: var(--color-white) !important;
}

.CompactSwitcher__Label {
  margin-right: 20px;
}