.RecurrenceDetailLoading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RecurrenceAccordion .Accordion__Head {
  padding: 0;
}

.RecurrenceDeleteDialogDesktop {
  min-width: 500px;
}

.RecurrenceDeleteDialogDesktop__Content {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.RecurrenceDeleteDialogMobile__Content {
  height: calc(100vh - 120px);
  margin: 0 20px;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.RecurrenceDeleteDialogMobile__ButtonGroup {
  display: flex;
  justify-content: flex-end;
}

.RecurrenceDeleteDialogDesktop__Button,
.RecurrenceDeleteDialogMobile__Button {
  width: 50%;
  min-width: auto;
}

.RecurrenceDeleteDialogMobile__Button {
  height: 60px;
}

/* Desktop */
@media screen and (min-width: 900px) {
  .RecurrenceDetailActionsWrapper {
    padding-left: 20px;
  }
}
