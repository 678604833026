.icon {
  font-size: 22px !important;
  margin-bottom: 2px;
}

.active,
.active:hover,
.active:focus {
  border: 1px solid var(--color-input-selected-form);
}
