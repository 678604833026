.select {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  display: table;
  position: relative;
  margin-bottom: 16px;
  outline: none;
  cursor: pointer;
  color: var(--color-gray);
  user-select: none;
  width: 100%;
}

.select:last-of-type {
  margin-bottom: 0;
}

.select select {
  display: none;
}

.select .select__button {
  position: relative;
  width: 100%;
  height: 40px;
}

.select .select__button:hover .select__thumb .thumb-icon {
  position: relative;
}

.select .select__button:hover .select__thumb .thumb-icon::before,
.select .select__button:hover .select__thumb .thumb-icon::after {
  position: absolute;
  top: 50%;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0;
  background: var(--color-gray);
  width: 20px;
  height: 3px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
}

.select .select__button:hover .select__thumb .thumb-icon::before {
  left: 0;
}

.select .select__button:hover .select__thumb .thumb-icon::after {
  left: 15px;
}

.select .select__thumb {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 298;
  border-radius: 0;
  cursor: inherit;
  width: 34px;
  height: inherit;
  color: var(--color-white);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.select .select__thumb .thumb-icon {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  margin: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.select .select__thumb .thumb-icon::before,
.select .select__thumb .thumb-icon::after {
  position: absolute;
  top: 50%;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0;
  background: var(--color-gray);
  width: 20px;
  height: 3px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
}

.select .select__thumb .thumb-icon::before {
  left: 0;
}

.select .select__thumb .thumb-icon::after {
  left: 15px;
}

.select .select__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  border: 1px solid var(--color-input-normal-form);
  border-radius: 0;
  cursor: inherit;
  padding: 7px 16px 3px;
  width: 100%;
  height: 40px;
  -webkit-appearance: none;
}

.select .select__placeholder--invalid {
  border-color: var(--color-int-red);
}

.select .select__dropdown {
  position: absolute;
  right: 0;
  left: 0;
  margin-bottom: 20px;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  z-index: 299;
  border-radius: 0;
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.1);
  background: var(--color-white);
  width: 100%;
  height: auto;
  max-height: 0;
  overflow: hidden;
  color: var(--color-gray);
}

.select .select__dropdown .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--color-input-normal-form);
  border-top: 0;
  padding: 5px 16px 5px;
  width: 100%;
  height: 40px;
}

.select .select__dropdown .dropdown-item:last-child {
  border-radius: 0;
}

.select .select__dropdown .dropdown-item:hover,
.select .select__dropdown .dropdown-item--focused {
  border-color: var(--color-int-blue);
  background: var(--color-int-blue);
  color: var(--color-white);
}

.select .select__dropdown .dropdown-item--selected {
  color: var(--color-int-blue);
}

.select .select__dropdown .dropdown-item--selected.dropdown-item--focused {
  color: var(--color-white);
}

.select .select__dropdown .dropdown-item--selected::before {
  float: right;
  margin-right: -7px;
  font-family: sdx-icons;
  font-size: 16px;
  content: '';
}

.select .select__dropdown .dropdown-item--disabled,
.select .select__dropdown .dropdown-item--disabled:hover {
  border-color: var(--color-input-normal-form);
  background: none;
  cursor: not-allowed;
  color: rgba(51, 51, 51, 0.4);
}

.select .message {
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  transition: 150ms all cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10;
}

.select.invalid > .message {
  max-height: 50px;
  margin-top: 6px;
  opacity: 1;
  color: #ff8b2e;
}

.select .dropdown-group__item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--color-input-normal-form);
  border-top: 0;
  padding: 12px 15px 15px;
  width: 100%;
  height: 40px;
  background-color: #eef3f6;
  color: #666;
  pointer-events: none;
}

.select .dropdown-group__item:hover {
  cursor: default;
}

.select .dropdown-group .dropdown-item:last-child {
  border-radius: 0;
}

.select .dropdown-group:last-child .dropdown-item:last-child {
  border-radius: 0;
}

.select--closed .select__thumb .thumb-icon::before {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}

.select--closed .select__thumb .thumb-icon::after {
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}

.select--closed .select__dropdown {
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  opacity: 0.2;
}

.select--open .select__thumb {
  border-radius: 0;
}

.select--open .select__thumb .thumb-icon::before {
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}

.select--open .select__thumb .thumb-icon::after {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}

.select--open .select__placeholder {
  border-radius: 0;
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.1);
  color: var(--color-gray);
}

.select--open .select__dropdown {
  max-height: 512px;
  overflow-y: auto;
}

.select--disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.select--disabled .select__button:hover .select__thumb {
  background: none;
  color: var(--color-gray);
}

.select--disabled .select__button:hover .select__thumb .thumb-icon {
  position: relative;
}

.select--disabled .select__button:hover .select__thumb .thumb-icon::before,
.select--disabled .select__button:hover .select__thumb .thumb-icon::after {
  position: absolute;
  top: 50%;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0;
  background: var(--color-gray);
  width: 20px;
  height: 3px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
}

.select--disabled .select__button:hover .select__thumb .thumb-icon::before {
  left: 0;
}

.select--disabled .select__button:hover .select__thumb .thumb-icon::after {
  left: 15px;
}

.select--minimal .select__button:hover .select__thumb {
  background: none;
  color: var(--color-int-blue--active);
}

.select--minimal .select__button:hover .select__thumb .thumb-icon {
  position: relative;
}

.select--minimal .select__button:hover .select__thumb .thumb-icon::before,
.select--minimal .select__button:hover .select__thumb .thumb-icon::after {
  position: absolute;
  top: 50%;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0;
  background: var(--color-int-blue--active);
  width: 20px;
  height: 3px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
}

.select--minimal .select__button:hover .select__thumb .thumb-icon::before {
  left: 0;
}

.select--minimal .select__button:hover .select__thumb .thumb-icon::after {
  left: 15px;
}

.select--minimal .select__placeholder {
  border: 0;
  border-bottom: 1px solid var(--color-input-normal-form);
  border-radius: 0;
  box-shadow: none;
  color: var(--color-gray);
}

.select--minimal .select__dropdown {
  border: 0;
  border-radius: 0;
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.15);
}

.select--minimal .select__dropdown .dropdown-item {
  border: 0;
  border-bottom: 1px solid var(--color-input-normal-form);
  border-radius: 0;
}

.select--minimal .select__dropdown .dropdown-item:last-child {
  border-bottom: 0;
  border-radius: 0;
}

.select--minimal.select--open .select__placeholder {
  border-color: transparent;
}

select {
  display: block;
  position: relative;
  border: 1px solid var(--color-input-normal-form);
  border-radius: 0;
  background: var(--color-white);
  cursor: pointer;
  padding: 12px 55px 12px 15px;
  width: auto;
  height: 40px;
  color: var(--color-gray);
  -webkit-appearance: none;
}
