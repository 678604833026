.Modal {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: var(--color-white);
}

.Modal--mobile {
  height: 100%;
  width: 100%;
}

.Modal--ios {
  position: fixed;
  overflow: hidden;
}
