.EquipmentCard {
  height: 100%;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--baseline-2) 20px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  font-weight: 400;
  flex-wrap: wrap;
  outline: none;
  position: relative;
}

.EquipmentCard:after {
  height: calc(100% - var(--baseline) * 2);
  content: '';
  width: 5px;
  position: absolute;
  left: 0;
  top: var(--baseline);
}

.EquipmentCard--unknown.EquipmentCard:after {
  background-color: var(--color-gray-20);
}

.EquipmentCard--laptop.EquipmentCard:after {
  background-color: var(--colors-equipment-laptop);
}

.EquipmentCard--ucc.EquipmentCard:after {
  background-color: var(--colors-equipment-ucc);
}

.EquipmentCard--beamer.EquipmentCard:after {
  background-color: var(--colors-equipment-beamer);
}

.EquipmentCard--whiteboard.EquipmentCard:after {
  background-color: var(--colors-equipment-whiteboard);
}

.EquipmentCard--digitalwhiteboard.EquipmentCard:after {
  background-color: var(--colors-equipment-digitalwhiteboard);
}

.EquipmentCard--flipchart.EquipmentCard:after {
  background-color: var(--colors-equipment-flipchart);
}

.EquipmentCard--presenter.EquipmentCard:after {
  background-color: var(--colors-equipment-presenter);
}

.EquipmentCard--planner.EquipmentCard:after {
  background-color: var(--colors-equipment-planner);
}

.EquipmentCard--audio.EquipmentCard:after {
  background-color: var(--colors-equipment-audio);
}

.EquipmentCard--car.EquipmentCard:after {
  background-color: var(--colors-equipment-car);
}

.EquipmentCard--parking.EquipmentCard:after {
  background-color: var(--colors-equipment-parking);
}

/* EquipmentType */
.EquipmentType--unknown {
  color: var(--color-gray-20);
}

.EquipmentType--laptop {
  color: var(--colors-equipment-laptop);
}

.EquipmentType--ucc {
  color: var(--colors-equipment-ucc);
}

.EquipmentType--beamer {
  color: var(--colors-equipment-beamer);
}

.EquipmentType--whiteboard {
  color: var(--colors-equipment-whiteboard);
}

.EquipmentType--digitalwhiteboard {
  color: var(--colors-equipment-digitalwhiteboard);
}

.EquipmentType--flipchart {
  color: var(--colors-equipment-flipchart);
}

.EquipmentType--presenter {
  color: var(--colors-equipment-presenter);
}

.EquipmentType--planner {
  color: var(--colors-equipment-planner);
}

.EquipmentType--audio {
  color: var(--colors-equipment-audio);
}

.EquipmentType--car {
  color: var(--colors-equipment-car);
}

.EquipmentType--parking {
  color: var(--colors-equipment-parking);
}
