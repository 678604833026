.BookingCollectionResourceFilter {
  width: 40%;
  max-width: 600px;
  position: fixed;
  right: 0;
  top: 0;
  background-color: var(--color-horizon);
  z-index: 99999;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.BookingCollectionResourceFilter .Header {
  border: none;
}

.BookingCollectionResourceFilter .Header .Header__Title {
  text-align: left;
  padding-left: 20px;
  box-shadow: 3px 0 10px var(--color-gray-20);
}

.filter-panel-enter {
  opacity: 0;
}

.filter-panel-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.filter-panel-exit {
  opacity: 1;
}

.filter-panel-exit-active {
  opacity: 0;
  transition: opacity 200ms;
 }
