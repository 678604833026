.body {
  display: flex;
  align-items: center;
  min-height: 60px;
  background-color: var(--color-horizon);
  padding: 9px 20px;
  border-bottom: 1px solid var(--color-gray-20);
}

.item {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.item:first-child {
  padding-left: 0;
}

.itemInputDate {
  flex: 1 1 0;
  min-width: 264px;
  max-width: 362px;
}

.item > i {
  font-size: 32px;
  padding-right: 10px;
}

.inputLocationPicker {
  min-width: 300px;
  max-width: 100%;
}

.inputDate {
  width: 100%;
}

.inputTime {
  min-width: 180px;
}
