.EquipmentFilter {
  padding-top: 20px;
}

.EquipmentFilter__Title {
  padding: 0 20px;
  font-weight: 500;
  text-align: left;
}

.EquipmentFilter__Section {
  display: flex;
  padding: calc(var(--baseline-2) - 5px) 15px;
  max-width: 600px;
}

.EquipmentFilter__Icon {
  margin-top: 2px;
  margin-left: -2px;
  font-size: 34px;
  width: 60px;
}

.EquipmentFilter__Content {
  width: 100%;
}

.EquipmentBoxes {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.EquipmentBox {
  padding: 12px 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.EquipmentFilter__Content.EquipmentBoxes > .EquipmentBox {
  width: calc(25% - 10px);
  margin: 5px;
}