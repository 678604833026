.EmptyState {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  color: var(--color-gray);
  text-align: center;
}

.EmptyState--centered {
  align-items: center;
}

.EmptyState__Icon {
  font-size: 56px;
}
