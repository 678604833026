.AlternativeLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--baseline-2) 0;
}

.AlternativeSearchResults {
  margin: 0 auto;
}

.AlternativeSearchResultsAccordion {
  width: 100%;
}

.AlternativeSearchResultsAccordion:first-child {
  border-top: 1px solid var(--color-gray-20);
}

.AlternativeSearchResultsAccordion__Head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;
  background-color: var(--color-horizon);
  border-bottom: 1px solid var(--color-gray-20);
  width: 100%;
}

.AlternativeSearchResultsAccordion__Title {
  font-weight: 500;
  color: var(--color-int-orange);
}

.AlternativeSearchResultsAccordion__Head:focus {
  outline: none;
}

.AlternativeSearchResultsAccordion__Icon {
  font-size: 34px;
  color: var(--color-gray-mid);
}

.AlternativeSearchResultsSection {
  border-bottom: 1px solid var(--color-gray-20);
}

.AlternativeLoadMore {
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 900px) {
  .AlternativeLoadMore {
    text-align: right;
  }
}
