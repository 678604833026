.tooltip-container .tooltip {
  color: black;
  background-color: var(--color-white);
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: var(--baseline) 20px;
  font-size: 16px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  z-index: 999997;
  max-width: 600px;
  position: fixed;
}

.tooltip-container .tooltip > p {
  margin-bottom: 0;
}

.slick-track
{
    min-width:500px;
}