.body {
  padding: 80px 20px 20px 20px;
  display: flex;
  justify-content: center;
}

.bodyForm{
  width: 700px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  padding-bottom: 60px;
}

.button {
  margin-left: 20px;
}