.Tag {
  border: 1px solid var(--color-blue);
  border-radius: 50px;
  padding: 5px 15px 5px 22px;
  color: var(--color-blue);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  white-space: nowrap;
}

.Tag__Content {
  flex: auto;
  text-align: center;
}

.Tag__Close {
  background: transparent;
  width: auto;
  margin-left: 10px;
  font-size: 16px;
}
