.DropDown__option--highlight {
  background-color: transparent;
  color: var(--color-int-blue);
  font-weight: 500;
}

.DropDown__option:hover .DropDown__option--highlight,
.DropDown__option--is-focused .DropDown__option--highlight {
  color: var(--color-white);
  font-weight: 500;
}
