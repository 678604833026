.HelpButton {
  right: 0;
  color: var(--color-gray);
  border-bottom: none;
  position: relative;
  z-index: 299;
}

.HelpButton__Icon {
  line-height: 30px;
  font-size: 34px;
}

.HelpButton__Info {
  position: absolute;
  right: -8px;
  top: 4px;
  margin-top: 60px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-top: 0;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  padding: var(--baseline-2) 20px;
  text-align: left;
}

.HelpButton__Info.ArrowBox.ArrowUp:before {
  left: calc(100% - 41.5px) !important;
}

.HelpButtonInfo__Section {
  margin-bottom: var(--baseline-2);
}
