:root {
  /* Baseline */
  --baseline: 8px;

  /* Spaces */
  --baseline-2: 16px;
  --baseline-3: 24px;
  --baseline-4: 32px;
  --baseline-5: 40px;
  --baseline-6: 48px;

  /* Generic sizes */
  --border-width: 1px;
  --border-radius: 0px;

  --indicator-size: 14px;
  --indicator-spacing: 16px;

  /* Header */
  --header-size: 60px;

  /* Button sizes */
  --button-margin-horizontal: 12px;
  --button-margin-vertical: 16px;

  --button-border-width: 1px;
  --button-border-radius: 0px;

  --button-padding-horizontal: 24px;
  --button-padding-vertical: 12px;

  /* Form sizes */
  --form-input-height: 40px;
  --form-input-icon-margin: 4px;
}
