@font-face {
  font-family: 'TheSans';
  font-weight: 300;
  font-style: normal;
  src: url(../assets/fonts/TheSans/TheSansB_300_.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_300_.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_300_.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_300_.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_300_.svg#TheSansB_300_) format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 400;
  font-style: normal;
  src: url(../assets/fonts/TheSans/TheSansB_400_.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_400_.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_400_.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_400_.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_400_.svg#TheSansB_400_) format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 500;
  font-style: normal;
  src: url(../assets/fonts/TheSans/TheSansB_500_.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_500_.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_500_.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_500_.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_500_.svg#TheSansB_500_) format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 600;
  font-style: normal;
  src: url(../assets/fonts/TheSans/TheSansB_600_.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_600_.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_600_.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_600_.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_600_.svg#TheSansB_600_) format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 700;
  font-style: normal;
  src: url(../assets/fonts/TheSans/TheSansB_700_.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_700_.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_700_.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_700_.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_700_.svg#TheSansB_700_) format('svg');
}

/* TheSans Italic */
@font-face {
  font-family: 'TheSans';
  font-weight: 300;
  font-style: italic;
  src: url(../assets/fonts/TheSans/TheSansB_300i.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_300i.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_300i.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_300i.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_300i.svg#TheSansB_300i) format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 400;
  font-style: italic;
  src: url(../assets/fonts/TheSans/TheSansB_400i.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_400i.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_400i.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_400i.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_400i.svg#TheSansB_400i) format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 500;
  font-style: italic;
  src: url(../assets/fonts/TheSans/TheSansB_500i.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_500i.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_500i.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_500i.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_500i.svg#TheSansB_500i) format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 600;
  font-style: italic;
  src: url(../assets/fonts/TheSans/TheSansB_600i.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_600i.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_600i.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_600i.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_600i.svg#TheSansB_600i) format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 700;
  font-style: italic;
  src: url(../assets/fonts/TheSans/TheSansB_700i.eot) format('eot'),
    url(../assets/fonts/TheSans/TheSansB_700i.woff2) format('woff2'),
    url(../assets/fonts/TheSans/TheSansB_700i.woff) format('woff'),
    url(../assets/fonts/TheSans/TheSansB_700i.ttf) format('truetype'),
    url(../assets/fonts/TheSans/TheSansB_700i.svg#TheSansB_700i) format('svg');
}
