.ResourceImages {
  display: flex;
}

.ResourceImages .icon {
  position: absolute;
}

.ResourceImages__Image {
  max-height: 250px;
  overflow: hidden;
  aspect-ratio: 2/1;
  text-align: center;
}

.ResourceImages__Image--small {
  height: 150px;
}

.ResourceImages__Arrow {
  font-size: 50px;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: white;
  border: none;
  outline: none;
  background: transparent;
  z-index: 200;
}

.ResourceImages__Arrow--next {
  right: 0;
}

.ResourceImages__Arrow--prev {
  left: 0;
}

.ResourceImages__Arrow .icon:before {
  text-shadow: 0 0px 3px rgba(0, 0, 0, 0.4);
}

.ResourceImages__Dots {
  bottom: 10px;
  left: 0;
}

.ResourceImages__Dots li button:before,
.ResourceImages__Dots li.slick-active button:before {
  color: white;
  text-shadow: 0 0px 3px rgba(0, 0, 0, 0.4);
  transform: scale(1.5);
  opacity: 1;
}

.ResourceImages__Dots li:not(.slick-active) button:before {
  opacity: 0.75;
}

.ResourceImages__Image img {
  max-width: 100%;
  height: 100%;
  display: inline-flex;
}

@media screen and (min-width: 500px) and (max-width: 900px) {
  .ResourceImages__Image:not(.ResourceImages__Image--small) {
    max-height: 450px;
    overflow: hidden;
    aspect-ratio: 2/1;
    text-align: center;
  }

  .ResourceImages__Image:not(.ResourceImages__Image--small) img {
    max-width: 100%;
    height: 100%;
    display: inline-flex;
  }
}
