.RecurrenceExceptions {
  margin-bottom: 20px;
}

.RecurrenceException a,
.BookFormRecurrence__Update,
.RecurrenceException__Link {
  display: inline-block;
  margin-left: 10px;
}

.RecurrenceExceptions__Info {
  padding: 0 20px;
  margin-bottom: 20px;
}

.BookDetailRecurrence__Info {
  display: flex;
  color: var(--color-int-orange);
  margin-top: 10px;
}

.BookDetailRecurrence__Info i {
  margin-top: 3px;
}

.RecurrenceException__Link {
  white-space: nowrap;
}

@media only screen and (min-width: 380px) {
  .RecurrenceException__Date {
    display: inline-block;
    min-width: 310px;
  }
}

.RecurrenceException__Date--skipped {
  text-decoration: line-through;
}

.RecurrenceException__DateTime {
  white-space: nowrap;
}

.RecurrenceException__LinkIcon {
  margin-right: 2px;
}

.BookDetailRecurrence__Icon {
  margin-right: 10px;
}

.RecurrenceAccordion {
  margin-top: -16px;
}

.RecurrenceExceptions .Accordion__Head {
  padding: 0 20px;
}

.RecurrenceAccordion__Head {
  display: flex;
  align-items: center;
}

.RecurrenceAccordion .Accordion__Icon {
  color: var(--color-int-orange);
}

.BookFormRecurrenceUpdate {
  padding-top: 0;
  color: var(--color-sc-gray-mid);
}

.BookFormRecurrenceUpdate p {
  margin: 0;
}

.BookFormRecurrenceUpdate__Title {
  font-weight: 500;
}

.RecurrenceInfo__NotAccepted {
  margin-left: 5px;
}

.BookFormRecurrenceButton {
  padding: 30px 0;
  margin-top: -20px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.BookFormRecurrenceButton--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.BookFormRecurrenceButton__Label {
  font-weight: 500;
  margin: 0;
}

.BookFormRecurrenceButton__Icon {
  font-size: 32px;
}

.BookDetailRecurrence__Exceptions {
  color: var(--color-int-orange);
}

.BookDetailRecurrence__Exceptions--loading {
  display: flex;
  align-items: center;
  color: var(--color-int-blue);
  margin-bottom: 20px;
}

.BookDetailRecurrence__Exceptions--loading p {
  margin-left: 15px;
}

.RecurrenceAccordion__Body {
  margin-bottom: var(--baseline-2);
}

@media only screen and (max-width: 899px) {
  .RecurrenceException {
    margin: var(--baseline) 0;
  }

  .RecurrenceInfo__NotAccepted {
    display: none;
  }
}

.BookModalRecurrence__Title {
  font-weight: 500;
  margin-bottom: 0;
}

.BookModalRecurrence__Text {
  display: inline;
}

.BookFormRecurrenceBookingCollectionInfo {
  color: var(--color-sc-gray-mid);
  font-weight: 400;
}
