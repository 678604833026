.RoomFilter {
  padding-top: 20px;
}

.RoomFilter.horizontal {
  display: flex;
  padding: 20px 0;
}

.RoomFilter__Section {
  display: flex;
  margin: var(--baseline-2) 20px;
  max-width: 500px;
}

.RoomFilter__ShowMore {
  padding: 0 20px;
  text-align: right;
  font-weight: 500;
}

.RoomFilter__Title {
  padding: 0 20px;
  font-weight: 500;
  text-align: left;
}

.RoomFilter__Info {
  margin: var(--baseline-2) 20px;
  margin-left: 75px;
  text-align: left;
}

.RoomFilter__Section--person {
  align-items: center;
}

.RoomFilter__Icon {
  margin-top: 2px;
  margin-left: -2px;
  font-size: 34px;
  width: 60px;
}

.RoomFilter__Content {
  width: 100%;
}

.RoomFilter__Content > .SelectBox,
.EquipmentFilter__Content > .SelectBox {
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.RoomFilter__Divider {
  border: none;
  border-top: 1px solid var(--color-gray-20);
  margin: 20px 0;
}

.RoomFilter__Spacer {
  height: 60px;
}

.TypeBoxes,
.EquipmentBoxes,
.RoomSizeBoxes {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.TypeBox,
.EquipmentBox {
  padding: 12px 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SizeBox {
  padding: 12px 10px;
}

.RoomSizeBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}
.select__button {
  min-width: 220px !important;
}

.RoomFilter__Section.Floor__Filter {
  margin-bottom: 8px;
}

.RoomFilter__Section.Sector__Filter {
  margin-top: 0px;
}

.RoomFilter__Content button.select__button {
  background-color: white;
  height: 48px;
}

.RoomFilter__Content button.select__button .select__placeholder {
  border: none;
  box-shadow: none;
}

.RoomFilter__Content .select .select__dropdown .dropdown-item {
  border: none;
}

.mobile .RoomFilter__Content button.select__button {
  background-color: var(--color-horizon);
}
