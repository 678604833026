.ChangeRequestDetail__Loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChangeRequestDetail__Form {
  width: 700px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: var(--baseline);
}

.ChangeRequestDetail__Actions {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 100px);
  margin-bottom: 60px;
}

.ChangeRequestDetail__Actions button {
  margin-left: 20px;
}

.ChangeRequestRejectModal {
  width: 800px;
}

.ChangeRequestRejectModal__Label {
  margin-bottom: var(--baseline);
}

.ChangeRequestRejectModal__Actions {
  display: flex;
  justify-content: flex-end;
}

.ChangeRequestRejectModal__Button {
  margin-top: var(--baseline-2);
  margin-bottom: 20px;
}
