.Timeline {
  margin-top: var(--baseline-4);
}

/* Timeline headers */
.sidebar-header {
  border-right: 1px solid #d6d6d6 !important;
}

.react-calendar-timeline .rct-calendar-header {
  border-left: 0px;
  border-bottom: 0px;
  border-color: #d6d6d6;
}

.react-calendar-timeline .rct-header-root {
  background-color: #fff !important;
}

.react-calendar-timeline .rct-dateHeader.rct-dateHeader-primary {
  border-bottom: 1px solid #d6d6d6 !important;
  color: var(--color-black)!important;
  background-color: var(--color-background) !important;
  font-weight: 500;
}

.react-calendar-timeline .rct-dateHeader {
  background-color: var(--color-white) !important;
  border-left: 1px solid #bbb;
  border-bottom: 0px;
  line-height: normal;
  text-align: center;
}

.sticky {
  position: sticky;
  top: 60px;
  z-index: 100;
}


/* Timeline left column (rooms) */
.rct-sidebar-row-odd,
.rct-hl-odd {
  background-color: transparent !important;
}

.rct-hl-odd,
.rct-hl-even {
  border-color: rgb(214, 214, 214) !important;
}

.rct-outer {
  border-right: 1px solid rgb(214, 214, 214) !important;
}

.rct-day-6,
.rct-day-0 {
  background-color: var(--color-background) !important;
}


/* Timeline Vertical Marker (today) */
.react-calendar-timeline .rct-today {
  width: 3px !important;
  background: var(--color-sc-purple) !important;
}

/* Timeline horizontal lines */
.disabled-line {
  background-color: var(--color-background) !important;
}

/* Timeline booking items */

.react-calendar-timeline .rct-items .rct-item {
  border-color: 0.5px solid var(--color-white);
}

.react-calendar-timeline .rct-items .rct-item .rct-item-overflow .rct-item-content {
  padding: 0 !important;
}


.react-calendar-timeline .rct-item .rct-item-content {
  padding: 0;
  max-width: 90%;
}

.rct-item.CalendarBooking--my.selected:before,
.rct-item.CalendarBooking--my.selected:after,
.rct-item.CalendarBooking--forme.selected:before,
.rct-item.CalendarBooking--forme.selected:after {
  height: 5px;
  width: 5px;
  content: '';
  position: absolute;
  top: calc(50% - 2.5px);
  background-color: #fff;
}

.rct-item.selected:before {
  left: 0;
  cursor: w-resize;
}
.rct-item.selected:after {
  right: 0;
  cursor: e-resize;
}

.rct-item.CalendarBooking--my,
.rct-item.CalendarBooking--forme {
  background-color: var(--color-pink) !important;
}

.rct-item .CalendarBufferedBooking__Block {
  background-color: var(--color-int-blue-alpha);
}

.rct-item.CalendarBooking--my .CalendarBufferedBooking__Block,
.rct-item.CalendarBooking--forme .CalendarBufferedBooking__Block {
  background-color: var(--color-pink-alpha);
}

.rct-item.CalendarBooking--my.CalendarBooking--tentative .CalendarBufferedBooking__Block {
  background-color: var(--color-gray-alpha);
}

.rct-item.CalendarBooking--tentative {
  background-color: var(--color-sc-gray-mid) !important;
}

.rct-item:not(.CalendarBooking--my):not(.CalendarBooking--forme) {
  background-color: var(--color-int-blue) !important;
}
