.CreateResource {
  padding-top: 100px;
}

.CreateResource__Divider {
  border: 0;
  background-color: var(--color-gray-20);
  height: 1px;
}

.CreateResource__Form {
  width: 700px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.CreateResource__Form--submit {
  padding-top: 0;
  padding-bottom: 0;
}

.CreateResourceSwitch {
  width: 700px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.CreateResource__Address {
  display: flex;
}

.CreateResource__Street {
  width: 400px;
}

.CreateResource__Zipcode {
  padding-left: 20px;
  flex: 1;
  align-self: right;
}

.CreateResource__ValidationError {
  color: var(--color-input-invalid-text);
  margin-top: -8px;
  margin-bottom: var(--baseline-2);
}

.CreateResource__Submit {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 60px;
}

.ProfileSwitcher__Option {
  margin-bottom: 20px;
}

.ProfileSwitcher__Item {
  padding-left: 35px;
}

.ProfileSwitcher__Info {
  display: flex;
  color: var(--color-sc-gray-mid);
  margin-bottom: 10px;
}

.ProfileSwitcher__Icon {
  margin-top: 3px;
  margin-right: 10px !important;
}

.CreateResourceDetail {
  margin-top: 100px;
}

.ChangeRequestDetail__Form--NewResource .ChangeRequestDetail__Actions {
  width: 100%;
}
