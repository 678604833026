.Star {
  cursor: pointer;
}

.Star--medium {
  font-size: 24px;
}

.Star--small {
  font-size: 20px;
}

.Star--active {
  color: var(--color-int-blue);
}
