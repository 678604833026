.title {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.35px;
  font-weight: 600;
}

.modal {
  composes: title;
}

.dialog {
  composes: title;
  margin-top: 5px;
  margin-left: 20px;
}
