.SearchFormLocation__Section {
  padding: 20px;
  border-bottom: 1px solid #d6d6d6;
}

.SearchFormLocation__Section:last-child {
  border-bottom: none;
}

.SearchFormLocation__Section + .SearchFormLocation__Section {
  border-bottom: 1px solid #d6d6d6;
}

.SearchFormLocation__Content {
  max-width: 500px;
  margin: 0 auto;
}
