.NotAuthorized__Content,
.ErrorPage__Content {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.NotAuthorized__Content .EmptyState,
.ErrorPage__Content .EmptyState {
  width: 600px;
}
