.FormTimeAndSubmit {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FormTimeAndSubmit > .TimeSection {
  max-width: none;
}

.FormTimeAndSubmit .TimeInputRange {
  margin-bottom: 10px;
}
