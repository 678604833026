.Accordion {
  width: 100%;
}

.Accordion__Head {
  display: flex;
  cursor: pointer;
}

.Accordion__Head > div:first-child {
  flex: auto;
}

.Accordion__Icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 45px;
}
