.dialog {
  width: 520px;
  padding: 34px;
}

.intro {
  display: flex;
}

.sections {
  margin-top: 20px;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.subheader {
  font-weight: 600;
}
