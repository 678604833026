.BookFormSeperator {
  background-color: var(--color-gray-20);
  height: 1px;
  width: 100%;
  margin: 20px 0;
}

.BookFormSeperator--top {
  margin-top: 20px;
}

.BookFormSeperator--nospace {
  margin-top: -5px;
}

.BookFormSeperator--nomargin {
  margin-top: 0;
}

.BookFormProfile3 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BookFormProfile3__InformationIcon {
  text-align: center;
  padding: 40px 80px 0;
}

.BookFormProfile3__InformationText,
.BookFormProfile3__Notes {
  text-align: center;
  padding: 20px 20px 40px;
}

.BookFormProfile3__Notes {
  padding-top: 0;
}

.BookFormProfile3__NotesIcon {
  width: 28px;
  min-width: 28px;
  margin-top: 4px;
}

.BookFormProfile3__InformationText .Profile3 {
  display: block;
}

.BookFormProfile3__Icon {
  display: block;
  font-size: 72px;
}

.BookFormProfile2 {
  margin-top: auto;
  padding-top: 20px;
  width: 100%;
  color: var(--color-sc-gray-mid);
  display: flex;
  padding-right: 20px;
  margin-bottom: 20px;
}

.BookFormProfile2__Icon {
  margin-top: 3px;
  margin-right: 10px;
}

.BookFormDatePickerFlyout {
  position: absolute;
}

.BookFormTimeWarning {
  display: flex;
  align-items: center;
  margin-left: 50px;
  color: var(--color-int-orange);
}

.BookFormNotes {
  display: flex;
  color: var(--color-sc-gray-mid);
}

.BookFormTimeWarning i,
.BookFormNotes__Icon {
  width: 28px;
  min-width: 28px;
}

.BookFormNotes__Icon {
  margin-top: 3px;
}

.BookedForHelpButton {
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BookedForHelpButton .HelpButton__Info {
  margin-top: 50px;
}

.BookedForHelpButton__Info {
  width: 500px;
}

.BookFormMessage {
  margin-bottom: 10px;
}

.BookFormReminder {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.BookFormReminder__Icon {
  height: 34px;
  width: 50px;
  min-width: 50px;
}

.BookFormReminderAlarmClockIcon {
  margin-left: 1px;
  width: 32px;
  height: 32px;
}

.BookFormReminder label {
  font-weight: 300;
}

.BookFormTeams {
  display: flex;
  align-items: center;
}

.BookFormTeams__Icon {
  height: 34px;
  width: 50px;
  min-width: 50px;
}

.BookFormTeamsIcon {
  margin-left: 0px;
  width: 35px;
  height: 35px;
}

.BookFormTeams label {
  font-weight: 300;
}

.BookFormMaster {
  margin-bottom: 15px;
}

@media screen and (max-width: 1000px) {
  .BookedForHelpButton__Info {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .BookFormProfile2 {
    padding: 0;
  }

  .BookedForHelpButton__Info {
    width: 500px;
  }

  .BookDetailDatePicker {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .BookedForHelpButton__Info {
    width: 400px;
  }
}

@media screen and (max-width: 470px) {
  .BookedForHelpButton__Info {
    width: 250px;
  }
}
