.BookDetailProfile {
  margin-top: auto;
}

.BookDetailRecurring,
.BookDetailPrivate,
.BookDetailNotes,
.BookDetailBookingCollection,
.BookDetailRecurrenceUnhandledException {
  display: flex;
  color: var(--color-gray-60);
}

.BookDetailRecurrenceUnhandledException {
  color: var(--color-int-orange);
}

.BookDetailPrivate__Icon,
.BookDetailNotes__Icon,
.BookDetailBookingCollection__Icon,
.BookDetailRecurring__Icon,
.RecurrenceUnhandledException__Icon {
  width: 28px;
  min-width: 28px;
  margin-top: 3px;
}

.BookDetail__FormField {
  margin-bottom: 20px;
}

.BookDetailMessage {
  white-space: pre-line;
}

.BookDetailActions__ButtonGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.BookDetailActions__Button {
  margin-left: 20px;
}

/* Small Mobile Devices */
@media screen and (max-width: 900px) {
  .BookDetailActions__Button {
    width: 100%;
    margin-right: 20px;
    flex: 1 1;
    margin-right: 0;
    margin-left: 0;
  }

  .BookDetailActions__ButtonFixed {
    width: 100%;
    height: 100%;
    margin-right: 20px;
    flex: 1 1;
  }

  .BookDetailActions__ButtonHalf {
    width: 50%;
  }

  .BookDetailActions__ButtonGroup:last-child {
    margin-right: 0;
  }

  .BookDetailActions__ButtonGroup {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  .BookDetailActions__ButtonGroup:last-child {
    margin-right: 0;
  }
}

.BookDetailActions__ButtonWrapper {
  position: relative;
}
