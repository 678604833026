.BookingCollectionFilter {
  background-color: var(--color-horizon);
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid var(--color-gray-20);
}

.BookingCollectionFilter__Item {
  display: flex;
  align-items: center;
  margin-right: 40px;
  width: 500px;
}

.BookingCollectionFilter__Item > i {
  font-size: 38px;
  margin-right: 20px;
}

.BookingCollectionFilter__Resource {
  margin-left: auto;
}

.link.ResourceFilterIcon,
.ResourceFilterIcon {
  color: var(--color-gray);
  float: right;
  border-bottom: none;
}

.ResourceFilterIcon--active {
  color: var(--color-blue);
}

.link.ResourceFilterIcon:hover,
.link.ResourceFilterIcon:active,
.link.ResourceFilterIcon:focus,
.ResourceFilterIcon:hover {
  border-bottom: none;
}

.ResourceFilterIcon__Label {
  line-height: 11px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
}

.ResourceFilterIcon__Icon {
  font-size: 26px;
  line-height: 26px;
  top: 4px;
  right: 2px;
}
