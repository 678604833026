.Room {
  align-self: flex-start;
  position: relative;
  width: 100%;
}

.Room__Header,
.Room__Body {
  display: table;
  width: 100%;
  margin-bottom: 4px;
}

.Room__Name,
.Room__Building,
.Room__Type {
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
  width: 70%;
}

.Room__Name {
  width: 90%;
}

.Room__Type {
  width: 50%;
}

.Room__Name {
  font-weight: 500;
}

.Room__Name.Room__Name--warning,
.Room__People.Room__People--warning {
  color: var(--color-int-orange);
}

.Room__People,
.Room__Time {
  display: table-cell;
  width: 100%;
  text-align: right;
  color: var(--color-black);
  white-space: nowrap;
}

.Room__Building {
  font-weight: 400;
}

.Room__Favorite {
  display: table-cell;
  width: 100%;
  text-align: right;
}

.Room__Tray {
  margin-left: -2px;
  margin-top: 4px;
}

.Room__ProfileInformation {
  overflow: hidden;
  max-width: 100%;
}
