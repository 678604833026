.title {
  width: 180px;
  height: 43px;
  font-weight: 600;
}

.boxes {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  width: 306px;
  border-right: 1px solid var(--color-gray-20);
}

.box {
  width: 180px;
  padding: 12px 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.box:not(:last-child) {
  margin-bottom: 10px;
}
