.BookFormCollectionButton {
  padding: 30px 0;
  margin-top: -20px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.BookFormCollectionButton__Label {
  font-weight: 500;
  margin: 0;
}

.BookFormCollectionButton__Icon {
  font-size: 32px;
}

.BookFormCollectionResources {
  padding: 20px 0;
  width: 100%;
  background-color: var(--color-gray-extralight);
}

.BookFormCollectionResources__Title {
  font-weight: 500;
  margin-bottom: 10px;
}

.BookFormCollectionResources__Content {
  width: 800px;
  margin: 0 auto;
}

.BookFormCollectionPlaceholder {
  padding: 30px 0;
}

.BookFormCollectionPlaceholder b {
  font-weight: 500;
}

.BookFormCollectionResource {
  display: flex;
  align-items: center;
}

.BookFormCollectionResource__RemoveButton:focus {
  outline: none;
}

.BookFormCollectionResource__RemoveIcon {
  margin-left: 4px;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-link);
}

.BookFormCollectionResource__RemoveIcon:hover {
  color: var(--color-link--active);
}

@media all and (max-width: 1400px) {
  .BookFormCollectionResources__Content {
    width: 600px;
  }
}

@media all and (max-width: 1200px) {
  .BookFormCollectionResources__Content {
    width: 500px;
  }
}

@media all and (max-width: 1000px) {
  .BookFormCollectionResources__Content {
    width: 450px;
  }
}
