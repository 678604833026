.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);


  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rdp-cell {
  border: 1px solid var(--color-gray-20);
}

.rdp-caption_label, .rdp-head_cell {
  font-weight: 500;
}

.rdp-tfoot {
  width: 100%;
  text-align: right;
}

.DayPicker-TodayButton {
  margin-top: 6px;
  transition: color 200ms var(--button-easing);
  color: var(--color-link);
}

.DayPicker-TodayButton:hover {
  color: var(--color-link--active);
}

.rdp-day_today:not(.rdp-day_outside) {
  color: var(--color-daypicker-today);
  font-weight: 500;
}

.rdp-day_today--selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
  color: var(--color-white);
  background-color: var(--color-int-blue);
}
