.ck.ck-editor {
    width: 100% !important;
}

.ck.ck-editor__editable_inline>* {
    margin-bottom: 0;
}


.ck.ck-editor__editable_inline>ul, ol {
    margin: auto;
    padding: revert;
    list-style: disc;
}

.ck.ck-editor__editable_inline>ol {
    list-style: decimal;
}


.ck.ck-editor.invalid .ck.ck-editor__main>.ck-editor__editable {
    border-color: var(--color-input-invalid-form);
}