.TimeInputRange__Divider {
  margin: 0 10px;
}

.TimeInputRange__Error {
  flex-wrap: wrap;
  max-height: 50px;
  margin-top: 6px;
  opacity: 1;
  color: var(--color-input-invalid-text);
}

.TimeInputRange__Error .icon {
  margin-right: var(--form-input-icon-margin);
}

.TimeInputRange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.TimeInputRange .input-field {
  margin-bottom: 0;
}

.TimeInputRange__InputValidation {
  margin-top: var(--baseline);
}

@media screen and (max-width: 600px) {
  .TimeInputRange > div:not(.TimeInputRange__Divider) {
    flex: auto;
  }
}
