.ArrowBox {
  position: relative;
}

.ArrowBox:before {
  display: block;
  content: ' ';
  position: absolute;
}

/* Arrow up */
.ArrowBox.ArrowUp:before {
  background-image: url('../assets/images/ArrowUp.svg');
  height: 20px;
  width: 34px;
  left: 50%;
  top: -20px;
}

/* Arrow right */
.ArrowBox.ArrowRight:before {
  background-image: url('../assets/images/ArrowRight.svg');
  height: 34px;
  width: 19px;
  left: 100%;
  top: 50%;
}

/* Arrow down */
.ArrowBox.ArrowDown:before {
  background-image: url('../assets/images/ArrowDown.svg');
  height: 20px;
  width: 34px;
  left: calc(50% - 16px);
  top: 100%;
}

/* Arrow left */
.ArrowBox.ArrowLeft:before {
  background-image: url('../assets/images/ArrowLeft.svg');
  height: 34px;
  width: 19px;
  right: 100%;
  top: 50%;
}
