h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'TheSans', sans-serif;
  margin: 0;
  margin-bottom: var(--baseline-4);
  padding: 0;
  text-align: left;
  word-wrap: break-word;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child {
  margin-bottom: 0;
}

h1 {
  line-height: 48px;
  letter-spacing: -1.2px;
  font-size: 40px;
  font-weight: 300;
}

h2 {
  line-height: 40px;
  letter-spacing: -1px;
  font-size: 32px;
  font-weight: 300;
}

h3 {
  line-height: 32px;
  letter-spacing: -0.75px;
  font-size: 28px;
}

h3,
h3--light {
  font-weight: 300;
}

h3--semi-light {
  font-weight: 400;
}

h3--semi-bold {
  font-weight: 500;
}

h4 {
  line-height: 32px;
  letter-spacing: -0.35px;
  font-size: 24px;
}

h4,
h4--light {
  font-weight: 300;
}

h4--semi-light {
  font-weight: 400;
}

h4--semi-bold {
  font-weight: 500;
}

h5 {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
}

h5,
h5--semi-light {
  font-weight: 400;
}

h5--semi-bold {
  font-weight: 500;
}

h6 {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
}

h6,
h6--semi-light {
  font-weight: 400;
}

h6--semi-bold {
  font-weight: 500;
}

p {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  font-weight: 400;
}

address {
  font-style: normal;
}

strong {
  font-weight: 500;
}

em {
  font-style: italic;
}

.font {
  font-family: 'TheSans', sans-serif;
}

.font--sans {
  font-family: 'TheSans', sans-serif;
}

.font--light {
  font-weight: 300;
}

.font--semi-light {
  font-weight: 400;
}

.font--semi-bold {
  font-weight: 500;
}
