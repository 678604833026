.Popup {
  position: relative;
  visibility: hidden;
}

.Popup__Content {
  position: absolute;
  width: auto;
  outline: none;
  background-color: var(--color-white);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 99997;
}

.Popup--visible {
  visibility: visible;
}

.Popup .input-field input,
.Popup .radio [type='radio'] + label::before,
.Popup .radio [type='radio'] + label::after,
.Popup .checkbox [type='checkbox'] + label::before,
.Popup .checkbox [type='checkbox'] + label::after {
  transition: none;
}

.PopupSaveButton {
  width: 100%;
}
