.ChangeRequestFormField {
  margin-bottom: 20px;
  min-height: 60px;
}

.ChangeRequestFormField__Information {
  margin-bottom: 10px;
  padding-top: 4px;
  width: 100%;
  color: var(--color-sc-gray-mid);
  display: flex;
  align-items: center;
}

.ChangeRequestFormField__Warning {
  margin-bottom: 10px;
  padding-top: 4px;
  width: 100%;
  color: var(--color-sc-red);
  display: flex;
  align-items: center;
}

.Information__Icon {
  margin-right: 10px;
}

.ChangeRequestFormField__Required {
  color: var(--color-input-invalid-text);
}

.ChangeRequestFormField__OptionalLabel {
  color: var(--color-gray-40);
}

.ChangeRequestFormField__Reason {
  width: calc(100% - 100px);
  margin-bottom: var(--baseline-2);
}

.FormFieldPeople--invalid .InputTags {
  border-color: var(--color-input-invalid-form);
}

.FormFieldReadOnly {
  min-height: auto;
}

.FormFieldReadOnly .ChangeDetector__Children {
  display: flex;
}

.FormFieldReadOnly__Label {
  flex: auto;
  font-weight: 500;
  min-width: 150px;
  margin-right: 20px;
}
