.body {
  padding: 80px 20px 20px 20px;
}

.tableHead {
  display: flex;
}

.tableHeadTitle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 16px;
  margin: 0;
}

.buttonNewNews {
  height: 48px;
}