.WarningBox {
    background: #ff8b2f;
    color: white;
    display: flex;
    margin: 0 0 20px 0;
    padding: 10px;
}

.WarningBox > .Icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px 0 0;
    font-size: 50px;
}

.WarningBox > .Message {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}