.ProfileInformation {
  margin-top: auto;
  width: 100%;
}

.ProfileInformation--tentative,
.ProfileInformation--unknown {
  color: var(--color-int-blue);
}

.ProfileInformation--accepted {
  color: var(--color-int-green);
}

.ProfileInformation--declined {
  color: var(--color-int-red);
}
