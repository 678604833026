.ChangeRequestsList__Loader {
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChangeRequestsList__EmptyState {
  margin-top: calc(100vh / 5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ChangeRequestsListEmptyState__Body {
  display: flex;
  margin: var(--baseline-4) 10%;
}

.ChangeRequestsListEmptyState__Panel {
  width: 50%;
}

.ChangeRequestsListEmptyState__Panel:first-child {
  text-align: right;
  border-right: 1px solid var(--color-gray-20);
}

.ChangeRequestsListEmptyState__PanelContent {
  padding: var(--baseline-2) 40px;
}

.ChangeRequestsListEmptyState__Section:not(:last-child) {
  margin-bottom: var(--baseline-2);
}

.NewResourceButton {
  max-width: 100%;
}

.ChangeRequestsListHelpInfo {
  width: 650px;
}

.ChangeRequestsListHelpInfo__Section {
  margin-bottom: var(--baseline-2);
}

.ChangeRequestsList__TableHead {
  display: flex;
  justify-content: space-between;
}

.ChangeRequestsList__TableHead .NewResourceButton {
  margin: 0;
  margin-bottom: 20px;
}

.ChangeRequestsList__Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  margin-left: 16px;
  margin-top: 10px;
  text-align: left;
}

.ChangeRequestsListRow td a {
  font-size: 16px;
}

.ChangeRequestsListRow--important {
  font-weight: 500;
}

.ChangeRequestsListRow--failed td:not(:last-child),
.ChangeRequestsListRow--rejected td:not(:last-child) {
  color: var(--color-int-red--active);
}

.ChangeRequestsListRow--approved td:not(:last-child) {
  color: var(--color-int-green--active);
}

.ChangeRequestsListCell--name,
.ChangeRequestsListCell--building {
  max-width: 240px;
}

.ChangeRequestEmptyStateAfterFilter {
  max-width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}