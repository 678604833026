.FormSwitch {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 700px;
  margin: 0 auto;
}

.FormSwitch__Title {
  margin-bottom: 10px;
  font-weight: 500;
}

.FormSwitch__Title small {
  font-weight: normal;
  font-size: 1rem;
}
