.SearchResultCard,
.SearchResultCard > div,
.SearchResultCard > div > div {
  height: 100%;
}

.Book__SearchResult,
.Book__SearchResult > div,
.Book__SearchResult > div > div,
.Book__SearchResult > div > div > div {
  height: 100%;
}

.SearchResultCard__Tray {
  margin-top: 5px;
}

.RoomCardTooltipSource {
  display: inline-flex;
}
