.CalendarBooking {
  position: absolute;
  padding: 5px;
  height: 100%;
  width: 100%;
  border: 1px solid var(--color-white) !important;
  overflow: visible;
}

.CalendarBufferedBooking__Block {
  position: absolute;
  top: 1px;
  height: calc(100% - 2px);
  width: 5px;
  z-index: 80;
}

.CalendarBooking p {
  margin-bottom: 0;
}

.CalendarBooking__Content {
  float: left;
  width: auto;
  height: 50px;
  white-space: nowrap;
}

.CalendarBooking__Content strong {
  font-weight: bolder;
}
 
.Timeline--compact .CalendarBooking__Content {
  height: 25px !important;
  margin-top: -2px;
}

.Timeline--compact .CalendarBooking__Organizer {
  display: none;
} 

.CalendarBooking__Icon {
  float: right;
  width: 50px;
  height: 50px;
  margin-left: 5px;
  width: auto;
  display: flex;
  font-size: 34px;
  text-align: right;
  align-items: center;
}

.CalendarBooking__Icon--BookingCollection {
  margin-right: 4px;
}

.Timeline--compact .CalendarBooking__Icon {
  height: 25px;
  width: 25px;
  font-size: 20px;
  margin-top: -2px;
}

.CalendarBooking--virtual {
  background: repeating-linear-gradient(
    45deg,
    var(--color-blue-40),
    var(--color-blue-40) 10px,
    var(--color-int-blue) 10px,
    var(--color-int-blue) 20px
  ) !important;
}

.CalendarBooking--my,
.CalendarBooking--my.CalendarBooking--virtual,
.CalendarBooking--forme {
  background: initial !important;
  background-color: var(--color-pink) !important;
}

.CalendarBooking--selected:before,
.CalendarBooking--selected:after {
  height: 5px;
  width: 5px;
  content: '';
  position: absolute;
  top: calc(50% - 2.5px);
  background-color: #fff;
}

.CalendarBooking--selected:before {
  left: 0;
}
.CalendarBooking--selected:after {
  right: 0;
} 