.SearchFormSwitch {
  position: absolute;
  height: 50px;
  z-index: 200;
  background-color: white;
  width: 100%;
  top: 59px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: height 0.4s;
  padding-top: 5px;
}

.SearchFormSwitch--closed {
  height: 0;
  padding: 0;
}

.SearchFormSwitchIcon {
  margin-left: 5px;
}

.SearchFormSwitchRoom {
  width: 50%;
  float: left;
  padding-right: 40px;
  padding-top: 3px;
}
.SearchFormSwitchRoom--icon {
  float: right;
}

.SearchFormSwitchEquipment {
  width: 50%;
  float: left;
  padding-left: 40px;
}
.SearchFormSwitchEquipment--icon {
  font-size: 40px;
}

.SearchFormSwitchIcon--active {
  color: var(--color-int-blue--active);
}
