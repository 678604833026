.react-tooltip.tooltip.CalendarBookingTooltip {
  padding: 0 !important;
}

.CalendarBookingTooltip__Wrapper {
  min-width: 400px;
  max-width: 460px;
  line-height: 22px;
  letter-spacing: -0.1px;
  font-size: 16px;
  font-weight: 400;
}

.CalendarBookingTooltip__Body {
  padding: 0 20px;
  display: flex;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--color-gray-20);
}

.CalendarBookingTooltip__Subject {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-weight: 500;
}

.CalendarBookingTooltip__Building {
  margin-top: 3px;
  line-height: 14px;
}

.CalendarBookingTooltip__DateTime {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.CalendarBookingTooltip__Persons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  padding: 0 20px;
}

.CalendarBookingTooltip__Persons--big {
  height: 70px;
  line-height: 28px;
}

.CalendarBookingTooltip__Organizer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CalendarBookingTooltip__Organizer strong {
  font-weight: bolder;
}

.CalendarBookingTooltip__Seating {
  padding: 0 20px;
  display: flex;
  height: 60px;
  align-items: center;
  border-top: 1px solid var(--color-gray-20);
}

.CalendarBookingTooltip__ProfileInformation,
.CalendarBookingTooltip__Recurring,
.CalendarBookingTooltip__Private,
.CalendarBookingTooltip__BookingCollection,
.CalendarBookingTooltip__UnhandledRecurrenceException {
  padding: 0 20px;
  display: flex;
  height: 60px;
  align-items: center;
  border-top: 1px solid var(--color-gray-20);
  color: var(--color-sc-gray-mid);
}

.CalendarBookingTooltip__BookingCollectionContent,
.TooltipProfileInformation {
  display: flex;
}

.CalendarBookingTooltip__ProfileInformation,
.CalendarBookingTooltip__BookingCollection {
  height: 80px;
}

.CalendarBookingTooltip__UnhandledRecurrenceException {
  height: 100px;
}

.CalendarBookingTooltip__RecurringIcon,
.CalendarBookingTooltip__PrivateIcon,
.CalendarBookingTooltip__BookingCollectionIcon,
.TooltipProfileInformation__Icon,
.UnhandledRecurrenceException__Icon {
  width: 28px;
  min-width: 28px;
}

.CalendarBookingTooltip__BookingCollectionIcon,
.TooltipProfileInformation__Icon {
  margin-top: 2px;
}

.TooltipProfileInformation--unknown,
.TooltipProfileInformation--tentative {
  color: var(--color-int-blue);
}

.TooltipProfileInformation--accepted {
  color: var(--color-int-green);
}

.TooltipProfileInformation--declined,
.CalendarBookingTooltip__UnhandledRecurrenceException {
  color: var(--color-int-red);
}
