.RecurrenceExceptionSidebar {
  position: absolute;
  height: 100%;
  width: 400px;
}

.RecurrenceExceptionSidebar__Detail {
  margin: var(--baseline-2) 20px;
}

.RecurrenceExceptionSidebar__Shadow::before {
  display: block;
  content: '';
  position: absolute;
  width: 5px;
  height: 100%;
  top: 0px;
}

.RecurrenceExceptionSidebar__Shadow--left::before {
  left: -5px;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(173, 173, 173, .4));
}

.RecurrenceExceptionSidebar .DataQualityInformation {
  display: none;
}

.RecurrenceExceptionSidebar__Book {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.RecurrenceExceptionDeleteSidebar__Resource {
  width: auto;
  padding: 0 20px 0 20px;
}

.RecurrenceExceptionDeleteSidebar__Resource--desktop {
  margin-top: var(--baseline-2);
}

.RecurrenceExceptionDeleteSidebar__Date {
  width: auto;
  padding: 20px 20px 0 20px;
}

.RecurrenceExceptionDivider {
  background-color: var(--color-gray-20);
  height: 1px;
  width: 100%;
  margin: 20px 0;
}

.RecurrenceExceptionNotAcceptedInfo {
  margin: 0 20px var(--baseline-2) 20px;
}

.RecurrenceExceptionNotAcceptedInfo__Icon {
  margin-right: 5px;
}
