.Profile {
  display: flex;
}

.Profile__Icon {
  width: 28px;
  min-width: 28px;
  margin-top: 3px;
}

.Profile__Ok {
  color: var(--color-int-green);
}

.Profile__Warning {
  color: var(--color-int-blue);
}

.Profile__Error {
  color: var(--color-int-red);
}
