.checkbox + .checkbox {
  margin-top: 14px;
}

.checkbox.add-space {
  margin-bottom: 24px;
}

.checkbox p {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 0;
  padding-left: 36px;
  color: var(--color-sc-gray-mid);
}

.checkbox .message {
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  transition: 150ms all cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10;
}

.checkbox.invalid > .message {
  max-height: 50px;
  margin-top: 6px;
  opacity: 1;
  color: var(--color-input-invalid-text);
}

.checkbox [type='checkbox']:not(:checked),
.checkbox [type='checkbox']:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.checkbox [type='checkbox'] + label {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 32px;
  color: var(--color-sc-gray);
  user-select: none;
  backface-visibility: hidden;
}

.checkbox [type='checkbox'] + label::before,
.checkbox [type='checkbox'] + label::after {
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 0;
  content: '';
}

.checkbox [type='checkbox'] + label::before {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--color-gray-20);
  border-radius: 1px;
  background: white;
  width: 22px;
  height: 22px;
}

.checkbox [type='checkbox'] + label::after {
  transform: scale(0);
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 50% 50%;
  width: 22px;
  height: auto;
  text-align: center;
  line-height: 22px;
  color: var(--color-gray-40);
  font-family: sdx-icons;
  font-size: 16px;
  content: '';
}

.checkbox [type='checkbox']:hover + label::before {
  border: 1px solid var(--color-gray-20);
  background: white;
}

.checkbox [type='checkbox']:hover + label::after {
  transform: scale(0.5);
  color: var(--color-gray-20);
}

.checkbox [type='checkbox']:focus + label::before {
  border: 1px solid var(--color-blue);
}

.checkbox [type='checkbox']:focus + label::after {
  color: var(--color-blue);
}

.checkbox [type='checkbox']:checked + label::before {
  border: 1px solid var(--color-blue);
  background: white;
}

.checkbox [type='checkbox']:checked + label::after {
  transform: scale(1);
  color: var(--color-blue);
}

.checkbox [type='checkbox']:checked:focus + label::before {
  border: 1px solid var(--color-blue);
}

.checkbox [type='checkbox']:checked:focus + label::after {
  color: var(--color-blue);
}

.checkbox [type='checkbox']:disabled + label,
.checkbox [type='checkbox']:disabled + label + p {
  opacity: 0.4;
  pointer-events: none;
}

.hiddenscroll.touchevents .checkbox [type='checkbox']:hover + label::before {
  border: 1px solid var(--color-gray-20);
  background: white;
}

.hiddenscroll.touchevents .checkbox [type='checkbox']:hover + label::after {
  transform: scale(0);
  color: var(--color-gray-20);
}

.hiddenscroll.touchevents .checkbox [type='checkbox']:focus + label::before {
  border: 1px solid var(--color-blue);
}

.hiddenscroll.touchevents .checkbox [type='checkbox']:focus + label::after {
  color: var(--color-blue);
}

.hiddenscroll.touchevents .checkbox [type='checkbox']:checked + label::before {
  border: 1px solid var(--color-blue);
  background: white;
}

.hiddenscroll.touchevents .checkbox [type='checkbox']:checked + label::after {
  transform: scale(1);
  color: var(--color-blue);
}

.hiddenscroll.touchevents .checkbox [type='checkbox']:checked:focus + label::before {
  border: 1px solid var(--color-blue);
}

.hiddenscroll.touchevents .checkbox [type='checkbox']:checked:focus + label::after {
  color: var(--color-blue);
}

.checkbox--only [type='checkbox'] + label {
  display: inline;
  padding-left: 22px;
}

.form-inline .checkbox {
  display: inline-block;
  vertical-align: top;
}

.form-inline .checkbox + .checkbox {
  margin-top: 0;
  margin-left: 14px;
}

.form-group label,
.form-group .label {
  display: block;
  margin-bottom: 8px;
}
