.Equipment {
  align-self: flex-start;
  position: relative;
  width: 100%;
}

.Equipment__Header,
.Equipment__Body {
  display: table;
  width: 100%;
  margin-bottom: 4px;
}

.Equipment__Name,
.Equipment__Building {
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
  width: 70%;
}

.Equipment__Favorite {
  display: table-cell;
  width: 100%;
  text-align: right;
}

.Equipment__Name {
  font-weight: 500;
  width: 90%;
}

.Equipment__Time {
  display: table-cell;
  width: 100%;
  text-align: right;
  color: var(--color-black);
  white-space: nowrap;
}

.Equipment__Building {
  font-weight: 400;
}

.Equipment__ProfileInformation {
  overflow: hidden;
  max-width: 100%;
}
