.modal {
  width: 100vw;
  height: 60px;
}

.modal:active {
  transform: none;
}

.dialog {
  position: absolute;
  left: 0;
  width: 100%;
}
