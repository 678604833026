.Menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color-blue);
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--color-white);
  z-index: 400;
}

.Menu__LogoWrapper {
  flex: 1;
}

.Menu__Logo {
  height: 60px;
  padding: 10px 0px 10px 20px;
  width: 250px;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
}

@media all and (max-width: 1060px) {
  .Menu__Logo {
    width: 70px;
  }
  .Menu__Logo span {
    display: none;
  }
}

.Menu__Logo img {
  height: 100%;
  width: auto;
  margin-right: 10px;
}

.Menu__Item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  height: 100%;
  text-align: center;
  flex-direction: row;
  margin-right: 60px;
  transition: all 0.15s ease;
}

.Menu__Item--flexibleSpaces {
  margin: 0;
  background: #632289;
}

.Menu__Item > div {
  height: 100%;
}

.MenuLink,
.MenuLink:hover,
.MenuLink:focus {
  color: var(--color-white);
  height: 100%;
  border-bottom: none;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
}

.MenuLink--active {
  font-weight: 500;
}

.MenuLink--active::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-white);

  position: absolute;
  bottom: 0;
  left: calc(50% - 5px);
}

.MenuLink__Calendar i {
  margin-top: -3px;
}

@media all and (max-width: 899px) {
  .Menu {
    bottom: 0;
    top: auto;
    color: var(--color-black);
    background-color: var(--color-white);
    border-top: 1px solid var(--color-gray-20);
  }

  .Menu__Item {
    width: 25%;
    margin: 0;
  }

  .Menu__Item > div {
    width: 100%;
  }

  .MenuLink {
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    flex-direction: column;
    width: 100%;
    color: var(--color-black);
  }

  .MenuLink:hover {
    color: var(--color-int-blue--active);
  }

  .MenuLink--active {
    color: var(--color-int-blue);
  }

  .MenuLink__Label {
    font-size: 11px;
    line-height: 11px;
    white-space: nowrap;
  }

  .MenuLink i {
    margin: 0;
  }
}

@media all and (min-width: 900px) {
  .MenuLink__Icon {
    margin-right: 10px;
  }
}

.MenuDropdown .MenuLink.MenuLink--active::after {
  display: none;
}

.MenuLink i {
  font-size: 26px;
}

.Menu__Divider {
  height: 100%;
  width: 1px;
  background-color: var(--color-white);
}

.MenuDropdown__Items {
  position: absolute;
  right: 180px;
  top: -10px;
  min-width: 200px;
  margin-top: 75px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-top: 0;
  display: flex;
  flex-direction: column;
}

.MenuDropdown__Items.ArrowBox.ArrowUp:before {
  left: calc(100% - 56px) !important;
}

.MenuDropdown__Items li {
  width: 100%;
  border-bottom: 1px solid var(--color-gray-20);
  display: inline-block;
}

.MenuDropdown__Items li:last-child {
  border-bottom: 0;
}

.MenuDropdown__Items li:hover {
  background-color: var(--color-gray-extralight);
}

.MenuDropdown__Items li a {
  color: black;
  display: inline-block;
  text-align: right;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  text-decoration: none;
  border-bottom: 0;
}

.Menu__ExternalLink {
  color: white;
  border-bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.Menu__ExternalLink:hover,
.Menu__ExternalLink:focus {
  color: white;
  border-bottom: 0;
}