:root {
  /* General colors */
  --color-black: #000;
  --color-white: #fff;

  /* Brand colors */
  --color-sc-navy: #015;
  --color-sc-blue: #1af;
  --color-sc-red: #d12;
  --color-sc-purple: #516;
  --color-sc-gray: #333;
  --color-sc-gray-mid: #666;
  --color-sc-gray-light: #bbb;

  /* Core colors */
  --color-white: #fff;
  --color-navy: #015;
  --color-blue: #1781e3;
  --color-blue-40: #a2cdf4;
  --color-blue-20: #d1e6f9;
  --color-aluminum: #dde3e7;
  --color-aluminum-80: #e4e9ec;
  --color-horizon: #f5f5f5; /* not sdx standard horizon */
  --color-horizon-40: #f8fafb;
  --color-gray: #333;
  --color-gray-90: #474747;
  --color-gray-80: #5c5c5c;
  --color-gray-60: #858585;
  --color-gray-40: #adadad;
  --color-gray-20: #d6d6d6;
  --color-gray-mid: #666;
  --color-gray-light: #bbb;
  --color-gray-extralight: #eee;

  /* Interaction colors */
  --color-int-gray: #cfd5d9;
  --color-int-gray--active: #b1b9be;
  --color-int-blue: #1781e3;
  --color-int-blue--active: #0851da;
  --color-int-green: #25b252;
  --color-int-green--active: #008236;
  --color-int-red: #dd1122;
  --color-int-red--active: #be0000;
  --color-int-orange: #ff8b2e;

  /* Link Colors */
  --color-link: #1781e3;
  --color-link--active: #0851da;

  /* Button Colors */
  --color-button-primary: #1781e3;
  --color-button-primary--active: #0851da;
  --color-button-primary--disabled: var(--color-blue-40);
  --color-button-primary--disabled-text: rgba(255, 255, 255, 0.6);

  --color-button-secondary: #eee; /* not sdx */
  --color-button-secondary--active: #d6d6d6; /* not sdx */
  --color-button-secondary--disabled: #f6f6f6; /* not sdx */
  --color-button-secondary--disabled-text: #999; /* not sdx */

  --color-button-confirm: #25b252;
  --color-button-confirm--active: #008236;
  --color-button-confirm--disabled: rgba(37, 178, 82, 0.4);

  /* Content Colors */
  --color-apple: #91ba2f;
  --color-pink: #e61e64;

  /* Input Fields */
  --color-input-bg: #fff;
  --color-input-placeholder: #bbb;
  --color-input-label: #666;
  --color-input-normal-form: #d6d6d6;
  --color-input-normal-text: #333;
  --color-input-selected-form: #1781e3;
  --color-input-selected-text: #333;
  --color-input-disabled-bg: #f5f5f5; /* not sdx standard horizon */
  --color-input-disabled-form: #dde3e7;
  --color-input-disabled-text: #bbb;
  --color-input-invalid-form: #be0000;
  --color-input-invalid-text: #dd1122;
  --color-input-success-form: #666;
  --color-input-success-text: #333;
  --color-input-success-icon: #25b252;
  --color-input-autocomplete-bg: #fff;
  --color-input-autocomplete-bg-hover: #f5f5f5; /* not sdx standard horizon */
  --color-input-autocomplete-text: #333;
  --color-input-autocomplete-text: #333;
  --color-search-live-text-match: #0851da;

  /* Room Colors*/
  --colors-room-meeting: #5944c6;
  --colors-room-conference: #a63297;
  --colors-room-project: #551166;
  --colors-room-standup: #1781e3;
  --colors-room-training: #91ba2f;
  --colors-room-workshop: #0ea8a9;
  --colors-room-auditorium: #e61e64;
  --colors-room-hybridmeeting: #19657a;

  --colors-equipment-laptop: #5944c6;
  --colors-equipment-ucc: #11aaff;
  --colors-equipment-beamer: #a63297;
  --colors-equipment-whiteboard: #551166;
  --colors-equipment-flipchart: #114551;
  --colors-equipment-presenter: #1781e3;
  --colors-equipment-planner: #91ba2f;
  --colors-equipment-audio: #0ea8a9;
  --colors-equipment-car: #e61e64;
  --colors-equipment-parking: #19657a;

  /* Other Colors */
  --color-daypicker-today: #1781e3;
  --color-background: rgba(245, 245, 245, 0.6);

  /* Alpha Colors */
  --color-pink-alpha: rgba(231, 66, 100, 0.5);
  --color-int-blue-alpha: rgba(23, 129, 227, 0.5);
  --color-gray-alpha: rgba(102, 102, 102, 0.5);
}
