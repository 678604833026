.BookingCollectionLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 900px) {
  .BookingCollectionLoader {
    height: calc(100% - 60px);
  }
}
