.RecurringInformation {
  display: flex;
  margin-top: auto;
  width: 100%;
  color: var(--color-gray-60);
  padding-top: 10px;
}

.RecurringInformation i {
  margin-right: 10px;
  margin-top: 3px;
}
