.content {
  background-color: var(--color-horizon);
  border-left: 1px solid var(--color-gray-20);
  display: block;
  width: 0;
  transition: width 0.3s ease-out;
  will-change: width;
  height: calc(100vh - 240px);
  overflow: auto;
}

.visible {
  width: 315px;
}

.list {
  padding: 0 20px;
}

.header {
  padding: 0 20px;
  line-height: 60px;
  font-weight: 700;
}

.buttonLink {
  padding: 0 20px 10px 20px;
  float: right;
}

.loading {
  padding: 0 20px 10px 20px;
}

.emptyState {
  padding: 0 20px 10px 20px;
}