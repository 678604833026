.container {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: var(--color-white);
}

.icon {
  font-size: 34px;
  margin-right: 10px;
  margin-top: -3px;
}
