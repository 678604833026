.RadioGroup--horizontal {
  display: flex;
  align-items: center;
}

.RadioGroup--horizontal .radio {
  margin-top: 0 !important;
  margin-right: 30px;
}

.radio + .radio {
  margin-top: 14px;
}

.radio.add-space {
  margin-bottom: 24px;
}

.radio p {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 0;
  padding-left: 36px;
  color: var(--color-gray-mid);
}

.radio [type='radio']:not(:checked),
.radio [type='radio']:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.radio [type='radio'] + label {
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0;
  font-size: 16px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 36px;
  color: var(--color-input-normal-text);
  user-select: none;
  backface-visibility: hidden;
}
.radio [type='radio'] + label::before,
.radio [type='radio'] + label::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  margin-top: 1px;
  border-radius: 50%;
  content: '';
}
.radio [type='radio'] + label::before {
  transition: all 120ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  width: 22px;
  height: 22px;
}
.radio [type='radio'] + label::after {
  top: 6px;
  left: 6px;
  transition: all 120ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: 50% 50%;
  width: 10px;
  height: 10px;
}

.radio [type='radio']:not(:checked) + label::before {
  border: 2px solid var(--color-gray-40);
}

.radio [type='radio']:not(:checked) + label::after {
  transform: scale(0);
  background-color: var(--color-gray-40);
}

.radio [type='radio']:hover + label::before {
  border: 2px solid var(--color-blue);
}

.radio [type='radio']:hover + label::after {
  transform: scale(0.5);
  background-color: var(--color-blue);
}

.radio [type='radio']:focus + label::before {
  border: 2px solid var(--color-blue);
}

.radio [type='radio']:focus + label::after {
  background-color: var(--color-blue);
}

.radio [type='radio']:checked + label::before {
  border: 2px solid var(--color-blue);
}

.radio [type='radio']:checked + label::after {
  transform: scale(1);
  background-color: var(--color-blue);
}

.radio [type='radio']:checked:hover + label::before {
  border: 2px solid var(--color-blue);
}

.radio [type='radio']:checked:hover + label::after {
  transform: scale(1);
  background-color: var(--color-blue);
}

.radio [type='radio']:checked:focus + label::before {
  border: 2px solid var(--color-blue);
}

.radio [type='radio']:checked:focus + label::after {
  background-color: var(--color-blue);
}

.radio [type='radio']:disabled + label {
  opacity: 0.4;
  pointer-events: none;
}
.radio [type='radio']:disabled + label::before {
  border-color: var(--color-gray-40);
  background-color: transparent;
}

.radio [type='radio']:disabled:hover + label::before {
  border: 2px solid var(--color-gray-40);
}

.radio [type='radio']:disabled:hover + label::after {
  background: none;
}

.radio [type='radio']:disabled:checked + label::before {
  border: 2px solid var(--color-gray-40);
}

.radio [type='radio']:disabled:checked + label::after {
  background-color: var(--color-gray-40);
}

.radio [type='radio']:disabled:not(:checked) + label::before {
  border-color: var(--color-gray-40);
}

.radio--only [type='radio'] + label {
  padding-left: 22px;
}

.form-inline .radio {
  display: inline-block;
  vertical-align: top;
}
.form-inline .radio + .radio {
  margin-top: 0;
  margin-left: 14px;
}

.form-group label,
.form-group .label {
  display: block;
  margin-bottom: 8px;
}

.form-group .message {
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  transition: 150ms all cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10;
}

.form-group.invalid > .message {
  max-height: 50px;
  margin-top: 6px;
  opacity: 1;
  color: var(--color-input-invalid-text);
}
