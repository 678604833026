.BookingCollectionDesktop {
  height: 100vh;
}

.BookingCollectionDesktop__Content {
  display: flex;
  height: calc(100% - 120px);
}

.BookingCollectionDesktop__Sidebar {
  width: 400px;
  height: 100%;
}

.BookingCollectionDesktop__Results {
  height: 100%;
  overflow: hidden;
  flex: auto;
  padding-bottom: 20px;
}
