.modal {
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.content {
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
  min-height: calc(100% - 60px);
}

.head {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.sections {
  margin-top: 20px;
  margin-bottom: 30px;
}

.section {
  margin-bottom: 20px;
}

.subheader {
  font-weight: 600;
}
