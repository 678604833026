input[type='search'] {
  box-sizing: border-box;
}

input[type='file'] {
  display: block;
}

input[type='radio'],
input[type='checkbox'] {
  padding: 0;
  box-sizing: border-box;
}

label,
.label {
  display: inline-block;
  margin-bottom: 4px;
  cursor: text;
  max-width: 100%;
  color: var(--color-black);
  font-weight: 500;
  font-size: 1rem;
}

input,
textarea {
  border-radius: 0;
}

input::-ms-clear,
textarea::-ms-clear {
  display: none;
}

.input-field {
  position: relative;
  padding: 0;
}

.input-field .message {
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  z-index: 101;
  transition: 150ms all var(--standard-easing);
}

.input-field .icon {
  margin-right: var(--form-input-icon-margin);
}

.input-field:first-child input:not([type]),
.input-field:first-child input[type='text'],
.input-field:first-child input[type='password'],
.input-field:first-child input[type='email'],
.input-field:first-child input[type='url'],
.input-field:first-child input[type='time'],
.input-field:first-child input[type='date'],
.input-field:first-child input[type='datetime'],
.input-field:first-child input[type='datetime-local'],
.input-field:first-child input[type='tel'],
.input-field:first-child input[type='number'],
.input-field:first-child input[type='search'],
.input-field:first-child textarea {
  margin-top: 0;
}

.input-field:last-child {
  margin-bottom: 0;
}

.input-field input,
.input-field textarea {
  -webkit-border-radius: 0;
}

.input-field input::-ms-clear,
.input-field textarea::-ms-clear {
  display: none;
}

.input-field input:not([type]),
.input-field input[type='text'],
.input-field input[type='password'],
.input-field input[type='email'],
.input-field input[type='url'],
.input-field input[type='time'],
.input-field input[type='date'],
.input-field input[type='datetime'],
.input-field input[type='datetime-local'],
.input-field input[type='tel'],
.input-field input[type='number'],
.input-field input[type='search'],
.input-field textarea {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  position: relative;
  transition: all 150ms var(--standard-easing);
  z-index: 100;
  outline: none;
  border: var(--border-width) solid var(--color-input-normal-form);
  border-radius: var(--border-radius);
  background-color: var(--color-input-bg);
  padding: 0 16px;
  width: 100%;
  height: var(--form-input-height);
  color: var(--color-input-normal-text);
  font-weight: 300;
  user-select: text;
  backface-visibility: hidden;
}


.input-field input:not([type]) ~ .icon,
.input-field input[type='text'] ~ .icon,
.input-field input[type='password'] ~ .icon,
.input-field input[type='email'] ~ .icon,
.input-field input[type='url'] ~ .icon,
.input-field input[type='time'] ~ .icon,
.input-field input[type='date'] ~ .icon,
.input-field input[type='datetime'] ~ .icon,
.input-field input[type='datetime-local'] ~ .icon,
.input-field input[type='tel'] ~ .icon,
.input-field input[type='number'] ~ .icon,
.input-field input[type='search'] ~ .icon,
.input-field textarea ~ .icon {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 101;
  width: var(--form-input-height);
  height: var(--form-input-height);
  text-align: center;
  line-height: var(--form-input-height);
  color: var(--color-input-success-icon);
  font-size: 22px;
}

.input-field input:not([type])::placeholder,
.input-field input[type='text']::placeholder,
.input-field input[type='password']::placeholder,
.input-field input[type='email']::placeholder,
.input-field input[type='url']::placeholder,
.input-field input[type='time']::placeholder,
.input-field input[type='date']::placeholder,
.input-field input[type='datetime']::placeholder,
.input-field input[type='datetime-local']::placeholder,
.input-field input[type='tel']::placeholder,
.input-field input[type='number']::placeholder,
.input-field input[type='search']::placeholder,
.input-field textarea::placeholder {
  transition: 150ms opacity var(--standard-easing);
  opacity: 1;
  color: var(--color-input-placeholder);
}

.input-field input:not([type]):focus:not([readonly]),
.input-field input[type='text']:focus:not([readonly]),
.input-field input[type='password']:focus:not([readonly]),
.input-field input[type='email']:focus:not([readonly]),
.input-field input[type='url']:focus:not([readonly]),
.input-field input[type='time']:focus:not([readonly]),
.input-field input[type='date']:focus:not([readonly]),
.input-field input[type='datetime']:focus:not([readonly]),
.input-field input[type='datetime-local']:focus:not([readonly]),
.input-field input[type='tel']:focus:not([readonly]),
.input-field input[type='number']:focus:not([readonly]),
.input-field input[type='search']:focus:not([readonly]),
.input-field textarea:focus:not([readonly]) {
  border-color: var(--color-input-selected-form);
  color: var(--color-input-selected-text);
}

.input-field input:not([type]):focus:not([readonly]) + label,
.input-field input[type='text']:focus:not([readonly]) + label,
.input-field input[type='password']:focus:not([readonly]) + label,
.input-field input[type='email']:focus:not([readonly]) + label,
.input-field input[type='url']:focus:not([readonly]) + label,
.input-field input[type='time']:focus:not([readonly]) + label,
.input-field input[type='date']:focus:not([readonly]) + label,
.input-field input[type='datetime']:focus:not([readonly]) + label,
.input-field input[type='datetime-local']:focus:not([readonly]) + label,
.input-field input[type='tel']:focus:not([readonly]) + label,
.input-field input[type='number']:focus:not([readonly]) + label,
.input-field input[type='search']:focus:not([readonly]) + label,
.input-field textarea:focus:not([readonly]) + label {
  transform: translateY(0);
  opacity: 1;
}

.input-field input:not([type]):focus:not([readonly])::placeholder,
.input-field input[type='text']:focus:not([readonly])::placeholder,
.input-field input[type='password']:focus:not([readonly])::placeholder,
.input-field input[type='email']:focus:not([readonly])::placeholder,
.input-field input[type='url']:focus:not([readonly])::placeholder,
.input-field input[type='time']:focus:not([readonly])::placeholder,
.input-field input[type='date']:focus:not([readonly])::placeholder,
.input-field input[type='datetime']:focus:not([readonly])::placeholder,
.input-field input[type='datetime-local']:focus:not([readonly])::placeholder,
.input-field input[type='tel']:focus:not([readonly])::placeholder,
.input-field input[type='number']:focus:not([readonly])::placeholder,
.input-field input[type='search']:focus:not([readonly])::placeholder,
.input-field textarea:focus:not([readonly])::placeholder {
  opacity: 0;
}

.input-field input:not([type]):disabled,
.input-field input:not([type])[readonly],
.input-field input[type='text']:disabled,
.input-field input[type='text'][readonly],
.input-field input[type='password']:disabled,
.input-field input[type='password'][readonly],
.input-field input[type='email']:disabled,
.input-field input[type='email'][readonly],
.input-field input[type='url']:disabled,
.input-field input[type='url'][readonly],
.input-field input[type='time']:disabled,
.input-field input[type='time'][readonly],
.input-field input[type='date']:disabled,
.input-field input[type='date'][readonly],
.input-field input[type='datetime']:disabled,
.input-field input[type='datetime'][readonly],
.input-field input[type='datetime-local']:disabled,
.input-field input[type='datetime-local'][readonly],
.input-field input[type='tel']:disabled,
.input-field input[type='tel'][readonly],
.input-field input[type='number']:disabled,
.input-field input[type='number'][readonly],
.input-field input[type='search']:disabled,
.input-field input[type='search'][readonly],
.input-field textarea:disabled,
.input-field textarea[readonly] {
  opacity: 1;
  border-color: var(--color-input-disabled-form);
  background-color: var(--color-input-disabled-bg);
  color: var(--color-input-disabled-text);
}

.input-field input:not([type]):disabled + label,
.input-field input:not([type])[readonly] + label,
.input-field input[type='text']:disabled + label,
.input-field input[type='text'][readonly] + label,
.input-field input[type='password']:disabled + label,
.input-field input[type='password'][readonly] + label,
.input-field input[type='email']:disabled + label,
.input-field input[type='email'][readonly] + label,
.input-field input[type='url']:disabled + label,
.input-field input[type='url'][readonly] + label,
.input-field input[type='time']:disabled + label,
.input-field input[type='time'][readonly] + label,
.input-field input[type='date']:disabled + label,
.input-field input[type='date'][readonly] + label,
.input-field input[type='datetime']:disabled + label,
.input-field input[type='datetime'][readonly] + label,
.input-field input[type='datetime-local']:disabled + label,
.input-field input[type='datetime-local'][readonly] + label,
.input-field input[type='tel']:disabled + label,
.input-field input[type='tel'][readonly] + label,
.input-field input[type='number']:disabled + label,
.input-field input[type='number'][readonly] + label,
.input-field input[type='search']:disabled + label,
.input-field input[type='search'][readonly] + label,
.input-field textarea:disabled + label,
.input-field textarea[readonly] + label {
  color: var(--color-black);
}

.input-field input:not([type]):disabled,
.input-field input[type='text']:disabled,
.input-field input[type='password']:disabled,
.input-field input[type='email']:disabled,
.input-field input[type='url']:disabled,
.input-field input[type='time']:disabled,
.input-field input[type='date']:disabled,
.input-field input[type='datetime']:disabled,
.input-field input[type='datetime-local']:disabled,
.input-field input[type='tel']:disabled,
.input-field input[type='number']:disabled,
.input-field input[type='search']:disabled,
.input-field textarea:disabled {
  cursor: not-allowed;
  user-select: none;
}

.input-field input:not([type]).valid,
.input-field input:not([type]):valid,
.input-field input:not([type]).invalid,
.input-field input:not([type]):invalid,
.input-field input[type='text'].valid,
.input-field input[type='text']:valid,
.input-field input[type='text'].invalid,
.input-field input[type='text']:invalid,
.input-field input[type='password'].valid,
.input-field input[type='password']:valid,
.input-field input[type='password'].invalid,
.input-field input[type='password']:invalid,
.input-field input[type='email'].valid,
.input-field input[type='email']:valid,
.input-field input[type='email'].invalid,
.input-field input[type='email']:invalid,
.input-field input[type='url'].valid,
.input-field input[type='url']:valid,
.input-field input[type='url'].invalid,
.input-field input[type='url']:invalid,
.input-field input[type='time'].valid,
.input-field input[type='time']:valid,
.input-field input[type='time'].invalid,
.input-field input[type='time']:invalid,
.input-field input[type='date'].valid,
.input-field input[type='date']:valid,
.input-field input[type='date'].invalid,
.input-field input[type='date']:invalid,
.input-field input[type='datetime'].valid,
.input-field input[type='datetime']:valid,
.input-field input[type='datetime'].invalid,
.input-field input[type='datetime']:invalid,
.input-field input[type='datetime-local'].valid,
.input-field input[type='datetime-local']:valid,
.input-field input[type='datetime-local'].invalid,
.input-field input[type='datetime-local']:invalid,
.input-field input[type='tel'].valid,
.input-field input[type='tel']:valid,
.input-field input[type='tel'].invalid,
.input-field input[type='tel']:invalid,
.input-field input[type='number'].valid,
.input-field input[type='number']:valid,
.input-field input[type='number'].invalid,
.input-field input[type='number']:invalid,
.input-field input[type='search'].valid,
.input-field input[type='search']:valid,
.input-field input[type='search'].invalid,
.input-field input[type='search']:invalid,
.input-field textarea.valid,
.input-field textarea:valid,
.input-field textarea.invalid,
.input-field textarea:invalid {
  box-shadow: none;
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 22px 22px;
}

.input-field input:not([type]).valid ~ .icon,
.input-field input:not([type]):valid ~ .icon,
.input-field input:not([type]).invalid ~ .icon,
.input-field input:not([type]):invalid ~ .icon,
.input-field input[type='text'].valid ~ .icon,
.input-field input[type='text']:valid ~ .icon,
.input-field input[type='text'].invalid ~ .icon,
.input-field input[type='text']:invalid ~ .icon,
.input-field input[type='password'].valid ~ .icon,
.input-field input[type='password']:valid ~ .icon,
.input-field input[type='password'].invalid ~ .icon,
.input-field input[type='password']:invalid ~ .icon,
.input-field input[type='email'].valid ~ .icon,
.input-field input[type='email']:valid ~ .icon,
.input-field input[type='email'].invalid ~ .icon,
.input-field input[type='email']:invalid ~ .icon,
.input-field input[type='url'].valid ~ .icon,
.input-field input[type='url']:valid ~ .icon,
.input-field input[type='url'].invalid ~ .icon,
.input-field input[type='url']:invalid ~ .icon,
.input-field input[type='time'].valid ~ .icon,
.input-field input[type='time']:valid ~ .icon,
.input-field input[type='time'].invalid ~ .icon,
.input-field input[type='time']:invalid ~ .icon,
.input-field input[type='date'].valid ~ .icon,
.input-field input[type='date']:valid ~ .icon,
.input-field input[type='date'].invalid ~ .icon,
.input-field input[type='date']:invalid ~ .icon,
.input-field input[type='datetime'].valid ~ .icon,
.input-field input[type='datetime']:valid ~ .icon,
.input-field input[type='datetime'].invalid ~ .icon,
.input-field input[type='datetime']:invalid ~ .icon,
.input-field input[type='datetime-local'].valid ~ .icon,
.input-field input[type='datetime-local']:valid ~ .icon,
.input-field input[type='datetime-local'].invalid ~ .icon,
.input-field input[type='datetime-local']:invalid ~ .icon,
.input-field input[type='tel'].valid ~ .icon,
.input-field input[type='tel']:valid ~ .icon,
.input-field input[type='tel'].invalid ~ .icon,
.input-field input[type='tel']:invalid ~ .icon,
.input-field input[type='number'].valid ~ .icon,
.input-field input[type='number']:valid ~ .icon,
.input-field input[type='number'].invalid ~ .icon,
.input-field input[type='number']:invalid ~ .icon,
.input-field input[type='search'].valid ~ .icon,
.input-field input[type='search']:valid ~ .icon,
.input-field input[type='search'].invalid ~ .icon,
.input-field input[type='search']:invalid ~ .icon,
.input-field textarea.valid ~ .icon,
.input-field textarea:valid ~ .icon,
.input-field textarea.invalid ~ .icon,
.input-field textarea:invalid ~ .icon {
  display: none;
}

.input-field input:not([type]).valid,
.input-field input[type='text'].valid,
.input-field input[type='password'].valid,
.input-field input[type='email'].valid,
.input-field input[type='url'].valid,
.input-field input[type='time'].valid,
.input-field input[type='date'].valid,
.input-field input[type='datetime'].valid,
.input-field input[type='datetime-local'].valid,
.input-field input[type='tel'].valid,
.input-field input[type='number'].valid,
.input-field input[type='search'].valid,
.input-field textarea.valid {
  border-color: var(--color-input-success-form);
  padding-right: calc(var(form-input-height) + var(--form-input-icon-margin));
  color: var(--color-input-success-text);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Crect fill='none' width='30' height='30'/%3E%3Cpath fill='%2325b252' d='M14,24c0.38,0,0.7-0.21,0.87-0.51l9-16c0.27-0.48,0.1-1.09-0.38-1.36c-0.48-0.27-1.09-0.1-1.36,0.38l-8.35,14.85l-6.07-6.07 c-0.39-0.39-1.02-0.39-1.41,0s-0.39,1.02,0,1.41l7,7C13.48,23.9,13.74,24,14,24z'/%3E%3C/svg%3E");
}

.input-field input:not([type]).invalid,
.input-field input:not([type]).invalid:focus,
.input-field input[type='text'].invalid,
.input-field input[type='text'].invalid:focus,
.input-field input[type='password'].invalid,
.input-field input[type='password'].invalid:focus,
.input-field input[type='email'].invalid,
.input-field input[type='email'].invalid:focus,
.input-field input[type='url'].invalid,
.input-field input[type='url'].invalid:focus,
.input-field input[type='time'].invalid,
.input-field input[type='time'].invalid:focus,
.input-field input[type='date'].invalid,
.input-field input[type='date'].invalid:focus,
.input-field input[type='datetime'].invalid,
.input-field input[type='datetime'].invalid:focus,
.input-field input[type='datetime-local'].invalid,
.input-field input[type='datetime-local'].invalid:focus,
.input-field input[type='tel'].invalid,
.input-field input[type='tel'].invalid:focus,
.input-field input[type='number'].invalid,
.input-field input[type='number'].invalid:focus,
.input-field input[type='search'].invalid,
.input-field input[type='search'].invalid:focus,
.input-field textarea.invalid,
.input-field textarea.invalid:focus,
.input-field .ck-editor.invalid,
.input-field .ck-editor.invalid:focus {
  border-color: var(--color-input-invalid-form);
  color: var(--color-input-invalid-text);
}

.input-field input:not([type]).invalid--inline,
.input-field input[type='text'].invalid--inline,
.input-field input[type='password'].invalid--inline,
.input-field input[type='email'].invalid--inline,
.input-field input[type='url'].invalid--inline,
.input-field input[type='time'].invalid--inline,
.input-field input[type='date'].invalid--inline,
.input-field input[type='datetime'].invalid--inline,
.input-field input[type='datetime-local'].invalid--inline,
.input-field input[type='tel'].invalid--inline,
.input-field input[type='number'].invalid--inline,
.input-field input[type='search'].invalid--inline,
.input-field textarea.invalid--inline {
  padding-right: calc(var(form-input-height) + var(--form-input-icon-margin));
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Crect fill='none' width='30' height='30'/%3E%3Cpath fill='%23ff8b2e' d='M15,2C7.83,2,2,7.83,2,15s5.83,13,13,13s13-5.83,13-13S22.17,2,15,2z M15,26C8.93,26,4,21.07,4,15S8.93,4,15,4 s11,4.93,11,11S21.07,26,15,26z M17,21c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2S17,19.9,17,21z M13,7h4v4l-1,6h-2l-1-6V7z'/%3E%3C/svg%3E");
}

.input-field input:not([type]).invalid ~ .message,
.input-field input[type='text'].invalid ~ .message,
.input-field input[type='password'].invalid ~ .message,
.input-field input[type='email'].invalid ~ .message,
.input-field input[type='url'].invalid ~ .message,
.input-field input[type='time'].invalid ~ .message,
.input-field input[type='date'].invalid ~ .message,
.input-field input[type='datetime'].invalid ~ .message,
.input-field input[type='datetime-local'].invalid ~ .message,
.input-field input[type='tel'].invalid ~ .message,
.input-field input[type='number'].invalid ~ .message,
.input-field input[type='search'].invalid ~ .message,
.input-field textarea.invalid ~ .message,
.input-field .ck-editor.invalid ~ .message
 {
  max-height: 50px;
  margin-top: 6px;
  opacity: 1;
  color: var(--color-input-invalid-text);
}

.input-field input:not([type]).validate + label,
.input-field input[type='text'].validate + label,
.input-field input[type='password'].validate + label,
.input-field input[type='email'].validate + label,
.input-field input[type='url'].validate + label,
.input-field input[type='time'].validate + label,
.input-field input[type='date'].validate + label,
.input-field input[type='datetime'].validate + label,
.input-field input[type='datetime-local'].validate + label,
.input-field input[type='tel'].validate + label,
.input-field input[type='number'].validate + label,
.input-field input[type='search'].validate + label,
.input-field textarea.validate + label {
  width: 100%;
  pointer-events: none;
}

/* custom (non-sdx) styles */
.input-field--autocomplete .autocomplete {
  position: absolute;
  transform: scale(1, 0);
  transform-origin: 50% 0;
  transition: all 200ms var(--standard-easing);
  background-color: #fff;
  opacity: 0.2;
  width: 100%;
  height: auto;
  margin-top: -1px;
  z-index: 101;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid var(--color-input-normal-form);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), -1px 1px 2px rgba(0, 0, 0, 0.1);
  color: var(--color-input-autocomplete-text);
}

.input-field--autocomplete .autocomplete strong {
  font-weight: 400;
  color: var(--color-search-live-text-match);
}

.input-field--autocomplete .autocomplete li:hover {
  background: var(--color-input-autocomplete-bg-hover);
}

.input-field--autocomplete .autocomplete li .autocomplete__result {
  margin: 0;
  padding: 7px 20px;
  outline: none;
}

.input-field--autocomplete .autocomplete li:not(:last-child) .autocomplete__result {
  border-bottom: var(--border-width) solid var(--color-input-normal-form);
}

.input-field--autocomplete.is-open input:not([type]),
.input-field--autocomplete.is-open input[type='text'],
.input-field--autocomplete.is-open input[type='password'],
.input-field--autocomplete.is-open input[type='email'],
.input-field--autocomplete.is-open input[type='url'],
.input-field--autocomplete.is-open input[type='time'],
.input-field--autocomplete.is-open input[type='date'],
.input-field--autocomplete.is-open input[type='datetime'],
.input-field--autocomplete.is-open input[type='datetime-local'],
.input-field--autocomplete.is-open input[type='tel'],
.input-field--autocomplete.is-open input[type='number'],
.input-field--autocomplete.is-open input[type='search'],
.input-field--autocomplete.is-open textarea {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-field--autocomplete.is-open .autocomplete {
  transform: scale(1, 1);
  opacity: 1;
  z-index: 999999;
}

.input-field--autocomplete .autocomplete li.js-hover,
.input-field--autocomplete .autocomplete li:hover {
  background: var(--color-input-autocomplete-bg-hover);
}

.autocomplete__result {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete__result--selected {
  color: var(--color-int-blue--active);
}

.autocomplete__result--selected::before {
  float: right;
  margin-right: -3px;
  font-family: sdx-icons;
  font-size: 16px;
  content: '';
}

.input-multiline textarea,
.input-field--multiline textarea {
  width: 100%;
  height: auto;
  padding: 11px 16px;
  resize: none;
  box-sizing: border-box;
}

.InputTags {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  overflow: hidden;
  min-height: 40px;
  border: 1px solid rgb(214, 214, 214);
  background-color: white;
  align-items: center;
  padding-left: 13px;
  padding-right: 16px;
  padding-top: 5px;
  padding-bottom: 2px;
}

.InputTags--focused {
  border-color: var(--color-input-selected-form);
  color: var(--color-input-selected-text);
}

.InputTags input {
  min-width: 25%;
  flex: 1 0;
  height: 26px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  padding-left: 3px !important;
  margin-bottom: 3px;
}

.InputTags input:focus {
  border-color: var(--color-input-selected-form);
  color: var(--color-input-selected-text);
}

.InputTag {
  padding: 0 5px;
  margin-right: 3px;
  margin-bottom: 3px;
  width: auto;
  text-align: center;
  background-color: transparent;
  display: flex;
  cursor: default;
}

.InputTag span {
  flex: auto;
  text-align: center;
}

.InputTag__Close {
  background: transparent;
  width: auto;
  margin-left: 10px;
}

.InputTag:hover {
  background-color: var(--color-aluminum);
}

.AutoCompleteLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.AutoCompleteLabel {
  position: absolute;
}

input::-ms-input-placeholder {
  color: var(--color-input-placeholder);
}

textarea {
  border: var(--border-width) solid var(--color-input-normal-form);
  background-color: var(--color-input-bg);
  min-height: var(--form-input-height);
  padding: 6px 16px 2px 16px !important;
  color: var(--color-input-normal-text);
  width: 99.9%;
  resize: none;
  box-sizing: border-box;
}

textarea:focus {
  border-color: var(--color-input-selected-form);
  color: var(--color-input-selected-text);
}

textarea::-webkit-input-placeholder {
  color: var(--color-input-placeholder);
}
textarea:-moz-placeholder {
  color: var(--color-input-placeholder);
  opacity: 1;
}
textarea::-moz-placeholder {
  color: var(--color-input-placeholder);
  opacity: 1;
}
textarea:-ms-input-placeholder {
  color: var(--color-input-placeholder);
}
textarea::-ms-input-placeholder {
  color: var(--color-input-placeholder);
}

textarea.invalid {
  border-color: var(--color-input-invalid-form);
  color: var(--color-input-invalid-text);
}

.TextArea__Error {
  flex-wrap: wrap;
  max-height: 50px;
  margin-top: 6px;
  opacity: 1;
  color: var(--color-input-invalid-text);
}

.TextArea__Error .icon {
  margin-right: var(--form-input-icon-margin);
}

.StickToTextfield {
  margin-top: -24px;
}
