.MaintenanceForm {
  padding-top: 60px;
}

.MaintenanceForm__Loader {
  max-width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.MaintenanceForm__Type,
.MaintenanceForm__Messages,
.MaintenanceForm__DateTime,
.MaintenanceForm__Submit,
.MaintenanceFormInput__Help {
  width: 700px;
  margin: 20px auto;
}

.MaintenanceForm__Messages {
  margin-bottom: 40px;
}

.MaintenanceFormInput {
  display: flex;
  margin-bottom: 20px;
}

.MaintenanceFormInput--message {
  margin-bottom: 0;
}

.MaintenanceFormInput--centered {
  align-items: center;
}

.MaintenanceFormInput__Icon {
  font-size: 34px;
  width: 50px;
}

.MaintenanceFormInput .input-field {
  flex: auto;
}

.MaintenanceFormInput__Input {
  width: 100%;
}

.MaintenanceFormInput__InputCounter {
  font-size: 14px;
  margin-top: -4px;
  margin-left: 50px;
  margin-bottom: 20px;
  color: var(--color-gray-60);
}

.MaintenanceFormInput__Actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  padding-bottom: 60px;
}

.MaintenanceFormInputSubmit {
  margin-left: 20px;
}

.MaintenanceFormInput__ValidationSummary {
  color: var(--color-input-invalid-text);
}

.MaintenanceForm__Divider {
  border: 0;
  background-color: var(--color-gray-20);
  height: 1px;
}

.MaintenanceFormInput__Help {
  position: relative;
}

.MaintenanceFormInput__HelpContent {
  position: absolute;
  right: -44px;
  top: 33px;
}

.MaintenanceFormInput__HelpButton .HelpButton__Info {
  margin-top: 50px;
}

.MaintenanceFormInput__Info {
  width: 720px;
}

.MaintenanceFormInfo__Intro {
  margin-bottom: 10px;
}

.MaintenanceFormInfo__Example {
  margin-top: 14px;
}
