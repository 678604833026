.Book__SearchResults {
  margin: 0 auto;
  z-index: 10;

  padding-left: 20px;
  height: calc(100vh - 240px);
  flex: 1 1;
  overflow: hidden;
}

.Book__SearchResults:focus {
  outline: none;
}

.ReactVirtualized__List:focus {
  outline: none;
}

.Book__SearchResult {
  border-bottom: 1px solid var(--color-gray-20);
}

.Book__SearchResult:hover {
  background-color: var(--color-horizon);
}

.Book__SearchResult--selected,
.Book__SearchResult--selected:hover {
  background-color: #fff;
}

.Book__SearchResult:focus {
  outline: none;
}

.SearchResultSelected,
.SearchResultSelected > div {
  height: 100%;
}

.SearchResultsLoader {
  max-width: 100%;
  height: calc(100vh - 340px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.SearchResultsLoader__Recurrence {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SearchResultsLoader__RecurrenceText {
  margin: 20px var(--baseline-2);
  text-align: center;
  color: var(--color-int-blue--active);
  max-width: 800px;
}

.SearchResultsInlineLoader {
  height: 60px;
  display: flex;
  align-items: center;
}

.SearchResultsInlineLoader__Loader {
  margin: 0 10px;
}

.SearchResultsWrapper {
  height: 100%;
}

.SearchResultsEmptyState {
  max-width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Book__EmptyState--warning {
  color: var(--color-int-orange);
}

@media all and (max-width: 600px) {
  .SearchResultsEmptyState {
    height: auto;
    padding: 30px 20px;
  }
}
