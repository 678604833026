.FormSubmit__ButtonGroup {
  width: 100%;
  display: flex;
}

.FormSubmit__Button {
  min-width: 0;
  width: 50%;
}

.FormSubmit__ButtonGroup .FormSubmit__Button:first-child {
  margin-right: 20px;
}
