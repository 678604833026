@font-face {
  font-family: 'sdx-icons';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/fonts/sdx-icons/sdx-icons.eot) format('eot'),
    url(../assets/fonts/sdx-icons/sdx-icons.woff2) format('woff2'),
    url(../assets/fonts/sdx-icons/sdx-icons.woff) format('woff'),
    url(../assets/fonts/sdx-icons/sdx-icons.ttf) format('truetype'),
    url(../assets/fonts/sdx-icons/sdx-icons.svg#sdx-icons) format('svg');
}

.icon {
  display: inline-block;
  text-transform: none;
  text-decoration: none;
  text-rendering: auto;
  line-height: 1;
  font-family: 'sdx-icons';
  font-size: inherit;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-001-account::before {
  content: '';
}

.icon-002-arrow-down::before {
  content: '';
}

.icon-003-arrow-left::before {
  content: '';
}

.icon-004-arrow-right::before {
  content: '';
}

.icon-005-arrow-up::before {
  content: '';
}

.icon-006-attachment::before {
  content: '';
}

.icon-007-backup::before {
  content: '';
}

.icon-008-bin::before {
  content: '';
}

.icon-009-calendar::before {
  content: '';
}

.icon-010-cancel::before {
  content: '';
}

.icon-011-check-mark::before {
  content: '';
}

.icon-012-check-mark-circle::before {
  content: '';
}

.icon-013-chevron-down::before {
  content: '';
}

.icon-014-chevron-left::before {
  content: '';
}

.icon-015-chevron-right::before {
  content: '';
}

.icon-016-chevron-up::before {
  content: '';
}

.icon-017-chevron-flat-down::before {
  content: '';
}

.icon-018-chevron-flat-left::before {
  content: '';
}

.icon-019-chevron-flat-right::before {
  content: '';
}

.icon-020-chevron-flat-up::before {
  content: '';
}

.icon-021-clock::before {
  content: '';
}

.icon-022-close::before {
  content: '';
}

.icon-023-download::before {
  content: '';
}

.icon-024-download-cloud::before {
  content: '';
}

.icon-025-edit::before {
  content: '';
}

.icon-026-exclamation-mark-circle::before {
  content: '';
}

.icon-027-flag::before {
  content: '';
}

.icon-028-folder-new::before {
  content: '';
}

.icon-029-folder-video::before {
  content: '';
}

.icon-030-forward::before {
  content: '';
}

.icon-031-forward-filled::before {
  content: '';
}

.icon-032-forward-message::before {
  content: '';
}

.icon-033-forward-message-2::before {
  content: '';
}

.icon-034-fullscreen-enter::before {
  content: '';
}

.icon-035-fullscreen-enter-2::before {
  content: '';
}

.icon-036-fullscreen-leave::before {
  content: '';
}

.icon-037-fullscreen-leave-2::before {
  content: '';
}

.icon-038-go-to-end::before {
  content: '';
}

.icon-039-go-to-end-filled::before {
  content: '';
}

.icon-040-go-to-start::before {
  content: '';
}

.icon-041-go-to-start-filled::before {
  content: '';
}

.icon-042-group::before {
  content: '';
}

.icon-043-heart::before {
  content: '';
}

.icon-044-home::before {
  content: '';
}

.icon-045-hourglass::before {
  content: '';
}

.icon-046-information-circle::before {
  content: '';
}

.icon-047-key::before {
  content: '';
}

.icon-048-link::before {
  content: '';
}

.icon-049-lock::before {
  content: '';
}

.icon-050-menu::before {
  content: '';
}

.icon-051-message::before {
  content: '';
}

.icon-052-minus::before {
  content: '';
}

.icon-053-more::before {
  content: '';
}

.icon-054-new::before {
  content: '';
}

.icon-055-okay::before {
  content: '';
}

.icon-056-pause::before {
  content: '';
}

.icon-057-pause-filled::before {
  content: '';
}

.icon-058-person::before {
  content: '';
}

.icon-059-play::before {
  content: '';
}

.icon-060-play-filled::before {
  content: '';
}

.icon-061-plus::before {
  content: '';
}

.icon-062-question-mark-circle::before {
  content: '';
}

.icon-063-record::before {
  content: '';
}

.icon-064-record-filled::before {
  content: '';
}

.icon-065-rename::before {
  content: '';
}

.icon-066-replay::before {
  content: '';
}

.icon-067-reply-message::before {
  content: '';
}

.icon-068-reply-message-2::before {
  content: '';
}

.icon-069-reply-message-all::before {
  content: '';
}

.icon-070-reply-message-all-2::before {
  content: '';
}

.icon-071-retry::before {
  content: '';
}

.icon-072-rewind::before {
  content: '';
}

.icon-073-rewind-filled::before {
  content: '';
}

.icon-074-save::before {
  content: '';
}

.icon-075-save-2::before {
  content: '';
}

.icon-076-save-3::before {
  content: '';
}

.icon-077-search::before {
  content: '';
}

.icon-078-settings::before {
  content: '';
}

.icon-079-shopping-trolley::before {
  content: '';
}

.icon-080-speech-bubble::before {
  content: '';
}

.icon-081-standby::before {
  content: '';
}

.icon-082-star::before {
  content: '';
}

.icon-083-stop::before {
  content: '';
}

.icon-084-stop-filled::before {
  content: '';
}

.icon-085-synchronise::before {
  content: '';
}

.icon-086-thumb-up::before {
  content: '';
}

.icon-087-upload::before {
  content: '';
}

.icon-088-upload-cloud::before {
  content: '';
}

.icon-089-volume-fortissimo::before {
  content: '';
}

.icon-090-volume-forte::before {
  content: '';
}

.icon-091-volume-piano::before {
  content: '';
}

.icon-092-volume-pianissimo::before {
  content: '';
}

.icon-093-volume-mute::before {
  content: '';
}

.icon-094-warning::before {
  content: '';
}

.icon-095-zoom-in::before {
  content: '';
}

.icon-096-zoom-out::before {
  content: '';
}

.icon-097-suitcase::before {
  content: '';
}

.icon-098-ambulance::before {
  content: '';
}

.icon-099-ambulance-2::before {
  content: '';
}

.icon-100-van::before {
  content: '';
}

.icon-101-delivery::before {
  content: '';
}

.icon-102-delivery-2::before {
  content: '';
}

.icon-103-applications::before {
  content: '';
}

.icon-104-assistant::before {
  content: '';
}

.icon-105-call-centre::before {
  content: '';
}

.icon-106-bill::before {
  content: '';
}

.icon-107-child-protection::before {
  content: '';
}

.icon-108-coins::before {
  content: '';
}

.icon-109-compass::before {
  content: '';
}

.icon-110-mobile-phone::before {
  content: '';
}

.icon-111-smartphone::before {
  content: '';
}

.icon-112-tablet::before {
  content: '';
}

.icon-113-laptop::before {
  content: '';
}

.icon-114-computer::before {
  content: '';
}

.icon-115-workstation::before {
  content: '';
}

.icon-116-credit-card::before {
  content: '';
}

.icon-117-directory::before {
  content: '';
}

.icon-118-document::before {
  content: '';
}

.icon-119-document-new::before {
  content: '';
}

.icon-120-document-excel::before {
  content: '';
}

.icon-121-document-powerpoint::before {
  content: '';
}

.icon-122-document-word::before {
  content: '';
}

.icon-123-document-pdf::before {
  content: '';
}

.icon-124-document-txt::before {
  content: '';
}

.icon-125-document-zip::before {
  content: '';
}

.icon-126-document-code::before {
  content: '';
}

.icon-127-e-mail::before {
  content: '';
}

.icon-128-film-camera::before {
  content: '';
}

.icon-129-film-camera-2::before {
  content: '';
}

.icon-130-film-camera-3::before {
  content: '';
}

.icon-131-film-reel::before {
  content: '';
}

.icon-132-folder::before {
  content: '';
}

.icon-133-football::before {
  content: '';
}

.icon-134-game::before {
  content: '';
}

.icon-135-gaming::before {
  content: '';
}

.icon-136-globe::before {
  content: '';
}

.icon-137-handset::before {
  content: '';
}

.icon-138-headphone::before {
  content: '';
}

.icon-139-headset::before {
  content: '';
}

.icon-140-index-card::before {
  content: '';
}

.icon-141-leaf::before {
  content: '';
}

.icon-142-lifesaver::before {
  content: '';
}

.icon-143-modem-wlan::before {
  content: '';
}

.icon-144-movie::before {
  content: '';
}

.icon-145-movie-2::before {
  content: '';
}

.icon-146-movie-3::before {
  content: '';
}

.icon-147-svod::before {
  content: '';
}

.icon-148-music::before {
  content: '';
}

.icon-149-navigation::before {
  content: '';
}

.icon-150-network::before {
  content: '';
}

.icon-151-office::before {
  content: '';
}

.icon-152-phone-basic::before {
  content: '';
}

.icon-153-phone-pro::before {
  content: '';
}

.icon-154-photo::before {
  content: '';
}

.icon-155-photo-camera::before {
  content: '';
}

.icon-156-postcard::before {
  content: '';
}

.icon-157-printer::before {
  content: '';
}

.icon-158-radio::before {
  content: '';
}

.icon-159-rss-feed::before {
  content: '';
}

.icon-160-wlan::before {
  content: '';
}

.icon-161-signal::before {
  content: '';
}

.icon-162-server::before {
  content: '';
}

.icon-163-sms::before {
  content: '';
}

.icon-164-translation::before {
  content: '';
}

.icon-165-transmitter::before {
  content: '';
}

.icon-166-tv::before {
  content: '';
}

.icon-167-tv-hd::before {
  content: '';
}

.icon-168-tv-2-0::before {
  content: '';
}

.icon-169-video-tape::before {
  content: '';
}

.icon-170-video-tape-2::before {
  content: '';
}

.icon-171-wearable-watch::before {
  content: '';
}

.icon-172-weather-cloud::before {
  content: '';
}

.icon-173-weather-rain::before {
  content: '';
}

.icon-174-weather-rain-unsettled::before {
  content: '';
}

.icon-175-weather-snow::before {
  content: '';
}

.icon-176-weather-snow-unsettled::before {
  content: '';
}

.icon-177-weather-storm::before {
  content: '';
}

.icon-178-weather-storm-unsettled::before {
  content: '';
}

.icon-179-weather-sun::before {
  content: '';
}

.icon-180-weather-sun-unsettled::before {
  content: '';
}

.icon-181-inbox::before {
  content: '';
}

.icon-182-drafts::before {
  content: '';
}

.icon-183-sent::before {
  content: '';
}

.icon-184-sent-2::before {
  content: '';
}

.icon-185-inbox::before {
  content: '';
}

.icon-186-drafts::before {
  content: '';
}

.icon-187-sent::before {
  content: '';
}

.icon-188-sent-2::before {
  content: '';
}

.icon-189-inbox::before {
  content: '';
}

.icon-190-drafts::before {
  content: '';
}

.icon-191-sent::before {
  content: '';
}

.icon-192-sent-2::before {
  content: '';
}

.icon-193-website::before {
  content: '';
}

.icon-194-website-2::before {
  content: '';
}

.icon-195-heart-filled::before {
  content: '';
}

.icon-196-exclamation-mark::before {
  content: '';
}

.icon-197-share::before {
  content: '';
}

.icon-198-chevron-down-small::before {
  content: '';
}

.icon-199-chevron-up-small::before {
  content: '';
}

.icon-200-chevron-left-small::before {
  content: '';
}

.icon-201-chevron-right-small::before {
  content: '';
}

.icon-202-clear-circle::before {
  content: '';
}

.icon-203-server::before {
  content: '';
}

.icon-204-cd::before {
  content: '';
}

.icon-205-bundle::before {
  content: '';
}

.icon-206-database::before {
  content: '';
}

.icon-207-firewall::before {
  content: '';
}

.icon-208-proxy-server::before {
  content: '';
}

.icon-209-settopbox::before {
  content: '';
}

.icon-210-puzzle::before {
  content: '';
}

.icon-211-skull::before {
  content: '';
}

.icon-212-fire::before {
  content: '';
}

.icon-213-setting::before {
  content: '';
}

.icon-214-present::before {
  content: '';
}

.icon-215-certificate::before {
  content: '';
}

.icon-216-waypointer::before {
  content: '';
}

.icon-217-eye::before {
  content: '';
}

.icon-218-eye-shut::before {
  content: '';
}

.icon-219-runtime::before {
  content: '';
}

.icon-220-list::before {
  content: '';
}

.icon-221-topology::before {
  content: '';
}

.icon-222-blueprint::before {
  content: '';
}

.icon-223-map-pointer::before {
  content: '';
}

.icon-224-console::before {
  content: '';
}

.icon-225-reboot::before {
  content: '';
}

.icon-226-reprovision::before {
  content: '';
}

.icon-227-speech-bubble-filled::before {
  content: '';
}

.icon-228-star-filled::before {
  content: '';
}

.icon-229-gateway::before {
  content: '';
}

.icon-230-flash::before {
  content: '';
}

.icon-231-rocket::before {
  content: '';
}

.icon-232-robot-neutral::before {
  content: '';
}

.icon-233-robot-happy::before {
  content: '';
}

.icon-234-robot-smile::before {
  content: '';
}

.icon-235-robot-laughing::before {
  content: '';
}

.icon-236-robot-wink::before {
  content: '';
}

.icon-237-robot-relaxed::before {
  content: '';
}

.icon-238-robot-surprized::before {
  content: '';
}

.icon-239-robot-confused::before {
  content: '';
}

.icon-240-robot-sad::before {
  content: '';
}

.icon-241-robot-broken::before {
  content: '';
}

.icon-242-umbrella::before {
  content: '';
}

.icon-243-security::before {
  content: '';
}

.icon-244-routed-network::before {
  content: '';
}

.icon-245-ip-sets::before {
  content: '';
}

.icon-246-resource-pool::before {
  content: '';
}

.icon-247-edge::before {
  content: '';
}

.icon-248-switch::before {
  content: '';
}

.icon-249-processor::before {
  content: '';
}

.icon-250-distributed-network::before {
  content: '';
}

.icon-251-legacy-network::before {
  content: '';
}

.icon-252-star-half::before {
  content: '';
}

.icon-253-filter::before {
  content: '';
}

.icon-254_idcard::before {
  content: '';
}

.icon-255_passport::before {
  content: '';
}

.icon-256_thumb_up_filled::before {
  content: '';
}

.icon-257_tag::before {
  content: '';
}

.icon-258_bell::before {
  content: '';
}

.icon-259_flash_multiple::before {
  content: '';
}

.icon-260_lightbulb::before {
  content: '';
}

.icon-261_devices::before {
  content: '';
}

.icon-262_smartphone_multiple::before {
  content: '';
}

.icon-263_document_indesign::before {
  content: '';
}

.icon-264_document_illustrator::before {
  content: '';
}

.icon-265_document_onenote::before {
  content: '';
}

.icon-266_document_eps::before {
  content: '';
}

.icon-267_document_photoshop::before {
  content: '';
}

.icon-268_document_visio::before {
  content: '';
}

.icon-269_document_publisher::before {
  content: '';
}

.icon-270_document_unknowndoc::before {
  content: '';
}

.icon-271_shop::before {
  content: '';
}

.icon-272_scissors::before {
  content: '';
}

.icon-273_battery::before {
  content: '';
}

.icon-274_battery_3::before {
  content: '';
}

.icon-275_battery_2::before {
  content: '';
}

.icon-276_battery_1::before {
  content: '';
}

.icon-277_battery_0::before {
  content: '';
}

.icon-278_battery_empty::before {
  content: '';
}

.icon-279_mouse::before {
  content: '';
}

.icon-280_pointer::before {
  content: '';
}

.icon-281_realmouse::before {
  content: '';
}

.icon-282_bug::before {
  content: '';
}

.icon-283_privacy::before {
  content: '';
}

.icon-284_ip::before {
  content: '';
}

.icon-285_patch::before {
  content: '';
}

.icon-286_microphone_1::before {
  content: '';
}

.icon-287_microphone_2::before {
  content: '';
}

.icon-288_handset_up::before {
  content: '';
}

.icon-289_handset_up_stroke::before {
  content: '';
}

.icon-290_handset_stroke::before {
  content: '';
}

.icon-291_software::before {
  content: '';
}

.icon-292_dynamic_storage::before {
  content: '';
}

.icon-293_wireless_data::before {
  content: '';
}

.icon-294_wireless_voice::before {
  content: '';
}

.icon-295_lips::before {
  content: '';
}

.icon-296_flag-filled::before {
  content: '';
}

.icon-297_alarmclock::before {
  content: '';
}

.icon-298_copy::before {
  content: '';
}

.icon-299_paste::before {
  content: '';
}

.icon-300_plus-box::before {
  content: '';
}

.icon-301_minus-box::before {
  content: '';
}

.icon-302_service::before {
  content: '';
}

.icon-303_business-phone::before {
  content: '';
}
