.BookDetailSidebar__FormField {
  margin-bottom: 20px;
}

.BookDetailSidebar__Favorite {
  display: flex;
}

.BookDetailSidebar__FavoriteIcon {
  margin-right: var(--baseline);
  color: var(--color-int-blue);
}

.BookDetailSidebar__FavoriteText {
  color: var(--color-int-blue);
}

.BookDetailSidebar__ChangeRequest {
  margin-top: 20px;
}

.BookDetailSidebar__ChangeRequestIcon {
  font-size: 20px;
  margin-right: 4px;
}

.SidebarSeatingPicker {
  margin-top: 20px;
  padding: 20px 20px;
  border-top: 1px solid var(--color-gray-20);
  border-bottom: 1px solid var(--color-gray-20);
  margin-left: -20px;
  width: calc(100% + 40px);
}

.SidebarSeatingPicker ul {
  list-style-type: disc;
  padding-left: 20px;
}

.SidebarSeatingPicker__Title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 500;
}

@media only screen and (min-width: 900px) {
  .BookDetailSidebar__FormField {
    margin-bottom: 10px;
    align-items: start;
  }
}
