.RoomTooltip {
  max-width: 300px;
  line-height: normal !important;
  white-space: normal !important;
}

.RoomTooltip__Images {
  height: 150px;
  width: 100%;
}

.RoomTooltip__Body {
  padding: 10px 15px;
}

.RoomTooltip__Header {
  display: flex;
  justify-content: space-between;
}

.RoomTooltip__Location {
  flex: auto;
  margin-right: 10px;
  font-weight: 500;
}

.RoomTooltip__LocationKey {
  color: var(--color-sc-gray-light);
}

.RoomTooltip__Name {
  white-space: nowrap;
}

.RoomTooltip__ManagedBy {
  display: flex;
}

.RoomTooltip__Footer {
  padding: 10px 15px;
  border-top: 1px solid var(--color-gray-20);
  background-color: var(--color-horizon);
}

.RoomTooltip.tooltip {
  padding: 0 !important;
}
