.BookModalDesktop {
  display: flex;
  height: calc(100% - 60px);
  background-color: var(--color-horizon);
}

.BookModalMobile {
  padding-top: 60px;
  padding-bottom: 80px;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Modal.Modal--ios .BookModalMobile {
  height: 100%;
}

.BookModalDesktop > div {
  height: 100%;
}

.BookModalDesktop .BookModal__Main {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.BookModal__Body {
  width: 100%;
  padding-bottom: 40px;
}

.BookModalDesktop .BookModal__Content {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.BookModalMobile .BookModal__Content {
  padding: 0 20px;
}

@media all and (max-width: 899px) and (min-width: 600px) {
  .BookModalMobile .BookModal__Content {
    padding: 0;
    width: 500px;
    margin: 0 auto;
  }
}

.BookModalMobile .BookModal__Content:first-child {
  padding-top: 20px;
}

.BookModalMobile .BookModal__Content--last {
  padding-bottom: 80px;
}

.BookModalDesktop .BookModal__Content--full {
  width: 100% !important;
}

.BookModalDesktop .BookModal__Content:first-child {
  padding-top: 40px;
}

.BookModalDesktop .BookModal__Sidebar {
  width: 500px;
  background-color: var(--color-white);
  border-left: 1px solid var(--color-gray-20);
}

.BookModalDesktop .BookModal__SidebarInfo {
  padding: 20px;
}

.BookModalInput {
  display: flex;
  margin-bottom: 20px;
}

.BookModal__Divider {
  border: 0;
  border-bottom: 1px solid var(--color-gray-20);
  width: 100%;
  margin: 0;
}

.BookModalSubmit {
  display: flex;
  justify-content: flex-end;
}

.BookModalDesktop .TimeInputRange {
  width: 400px;
}

.BookModal__Info {
  margin-bottom: 20px;
}

@media all and (max-width: 1400px) {
  .BookModalDesktop .BookModal__Content {
    width: 600px;
  }

  .BookModalDesktop .BookModal__Sidebar {
    width: 400px;
  }
}

@media all and (max-width: 1200px) {
  .BookModalDesktop .BookModal__Content {
    width: 500px;
  }
}

@media all and (max-width: 1000px) {
  .BookModalDesktop .BookModal__Content {
    width: 450px;
  }

  .BookModalDesktop .BookModal__Sidebar {
    width: 350px;
  }
}

.InformationEntry {
  display: flex;
}

.InformationEntry__Title {
  font-weight: bold;
  flex: auto;
}

.RecurrenceAccordion .BookModalInput {
  margin-bottom: 20px;
}

.BookModalInput .input-field,
.BookModalInput .DateInput {
  width: 100% !important;
}

.BookModalInput__Icon {
  margin-top: 2px;
  font-size: 34px;
  min-width: 50px;
  max-width: 50px;
}

.fit {
  max-width: 100%;
}

.fit--mobile {
  max-width: 100%;
  margin-top: 60px;
}

.BookModalValidationError {
  margin-left: 70px;
  margin-top: 5px;
}

.Separator {
  background-color: var(--color-gray-20);
  height: 1px;
  width: 100%;
  margin: 20px 0;
}

.Separator--top {
  margin-top: 20px;
}

.Separator-nospace {
  margin-top: -5px;
}

.RecurrenceUpdateModalDesktop .BookDetailPrivate {
  margin-left: 20px;
  margin-bottom: 20px;
  color: var(--color-sc-gray-mid);
}

.RecurrenceUpdateModalDesktop .BookFormProfile2 {
  padding-top: 0;
}

.RecurrenceUpdateModalMobile .BookDetailPrivate {
  margin-bottom: 20px;
  color: var(--color-sc-gray-mid);
}
