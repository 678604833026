.Header {
  height: var(--header-size);
  border-bottom: 1px solid var(--color-gray-20);
  position: relative;
  z-index: 10000;
}

.Header__Title {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: var(--header-size);
  font-weight: 500;
}

.link.Header__Item,
.Header__Item {
  font-size: 34px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
  z-index: 10;
}

.link.Header__Item--left,
.Header__Item--left {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: var(--baseline-2);
  height: 60px;
}

.link.Header__Item--right,
.Header__Item--right {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  margin-right: 20px;
}

.link.Header__Link,
.Header__Link {
  color: var(--color-gray);
  border-bottom: none;
}

.link.Header__Link--active,
.Header__Link--active {
  color: var(--color-int-blue--active);
}

.link.Header__Link:hover,
.link.Header__Link:focus,
.Header__Link:hover,
.Header__Link:focus {
  border-bottom: 0px;
}

.Header__Link--right {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  width: 100px;
}

.Header__Link-Icon {
  line-height: 25px;
  font-size: 26px;
}

.Header__Link-Title {
  width: 100%;
  text-align: center;
  margin: 0px;
  line-height: 11px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
}

/* adjust chevron icon positioning */
.Back-Link {
  margin-left: -7px;
}

/* adjust visual centering appearance */
.Feedback-Link .Header__Link-Title {
  padding-right: 3px;
}

.Header--dynamic {
  width: 100vw !important;
}

@media only screen and (min-width: 900px) {
  .Header.Header--fixed {
    position: fixed;
    z-index: 300;
    width: 100%;
    left: 0;
    top: 0;
    background-color: white;
  }
}

.Header.HeaderMobile--fixed {
  position: fixed;
  z-index: 300;
  width: 100%;
  left: 0;
  top: 0;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .Header__Title {
    position: absolute;
    text-align: center;
    line-height: var(--header-size);
    font-weight: 500;
    padding-left: 50px;
    padding-right: 60px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
