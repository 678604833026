.SearchTime {
  display: flex;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.SearchTime__Icon {
  margin-top: 3px;
  margin-left: -2px;
  font-size: 34px;
  max-width: 50px;
  min-width: 50px;
}

.SearchTime__Input {
  width: 100%;
}
