.body {
    padding: 10px 5px;
}

.heading {
    margin-bottom: 4px;
    max-width: 100%;
    color: var(--color-black);
    font-weight: 500;
    font-size: 1rem;
}

.input {
    padding-bottom: 10px;
}

.requiredText {
    font-size: 13px;
    color: var(--color-int-orange);
}