.Loader--large {
  background-size: 3750px 50px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url('./images/loader-spinner-plain-large.png');
  width: 50px;
  height: 50px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: anim-sheet-plain-large 1.25s steps(75) infinite;
  animation: anim-sheet-plain-large 1.25s steps(75) infinite;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .Loader--large {
    background-image: url('./images/loader-spinner-plain-large@2x.png');
  }
}

.Loader--small {
  background-size: 1875px 25px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url('./images/loader-spinner-plain-small.png');
  width: 25px;
  height: 25px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: anim-sheet-plain-small 1.25s steps(75) infinite;
  animation: anim-sheet-plain-small 1.25s steps(75) infinite;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .Loader--small {
    background-image: url('./images/loader-spinner-plain-small@2x.png');
  }
}

@keyframes anim-sheet-plain-large {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -3750px 0;
  }
}

@keyframes anim-sheet-plain-small {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -1875px 0;
  }
}
