.BookingCollectionDialog {
  width: 560px;
  display: flex;
}

.BookingCollectionDialog__Content {
  width: 560px;
}

.BookingCollectionDialog__Title {
  font-weight: 500;
}

.BookingCollectionDialogDescription__Text,
.BookingCollectionDialog__Ignore .checkbox [type='checkbox'] + label {
  font-weight: 400;
}

.BookingCollectionDialog__Description {
  display: flex;
}

.BookingCollectionDialog__Action {
  display: flex;
  justify-content: flex-end;
}

.BookingCollectionDialog__Title,
.BookingCollectionDialog__Description {
  margin-bottom: var(--baseline-2);
}

.BookingCollectionDialog__Ignore {
  margin-bottom: var(--baseline-3);
}

.BookingCollectionDialogDescription__Icon {
  padding-top: 2px;
  color: var(--color-blue);
  margin-right: 16px;
  font-size: 22px;
}

.BookingCollectionDialog__Ignore .checkbox [type='checkbox'] + label {
  color: var(--color-input-label);
}
