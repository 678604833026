.Booking {
  align-self: flex-start;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.Booking:focus {
  outline: none;
}

.Booking__Header,
.Booking__Body {
  display: table;
  width: 100%;
  margin-bottom: 4px;
}

.Booking__Name,
.Booking__Building {
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
  width: 70%;
}

.Booking__Name {
  font-weight: 500;
}

.Booking__People,
.Booking__Time {
  display: table-cell;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  color: var(--color-black);
}

.Booking__Building {
  font-weight: 400;
}

.Booking__Footer {
  margin-left: -2px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Booking__Tray {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.Booking__Submit {
  width: 100%;
}

.Booking__Information {
  display: flex;
}

.Booking__Type {
  width: 50%;
}

.Booking__RecurringInformation {
  margin-top: 20px;
}

.Booking__ResponseIcon {
  display: flex;
  justify-content: flex-end;
}
