.RecurrenceExceptionModalDesktop {
  width: 100%;
  height: calc(100% - 60px);
}

.RecurrenceExceptionModalDesktop__Results {
  height: 100%;
  display: flex;
}

.RecurrenceExceptionModalDesktop__Panel {
  width: 400px;
}

.RecurrenceExceptionModalDesktop__Panel:first-child {
  height: 100%;
  flex: auto;
}

.RecurrenceExceptionModalMobile {
  background-color: #fff;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.RecurrenceExceptionModalMobile__Content {
  padding-bottom: 0;
}

.RecurrenceExceptionResetButton {
  padding: 20px 20px 0 20px;
}

.RecurrenceExceptionResetButton button {
  width: 100%;
}
