.DataQualityInformation {
  color: var(--color-int-orange);
  display: flex;
  justify-content: center;
  outline: none;
  transition: max-height 0.5s;
}

.DataQualityInformation--centerVertical {
  align-items: center;
}

.DataQualityInformation--collapsed {
  max-height: 50px;
  overflow: hidden;
}

.DataQualityInformation--shown {
  max-height: 1000px;
}

.DataQualityInformation__Icon {
  font-size: 34px;
  padding-top: 3px;
  margin-right: 20px;
  height: 100%;
  display: block;
}

.DataQualityInformation__Icon--small {
  font-size: 20px;
  margin-right: var(--baseline);
}

.DataQualityInformation__Text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.DataQualityInformation__Text a {
  color: var(--color-int-orange);
  text-decoration: underline;
  transition: none;
}

.DataQualityInformation__Text a:hover {
  border-bottom: none;
}

.BookDetailInformation .DataQualityInformation {
  margin-top: 8px;
  justify-content: flex-start;
}

.BookDetailInformation .DataQualityInformation .DataQualityInformation__Text {
  flex: initial;
}
