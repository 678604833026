.CollectionSearchResults--desktop {
  width: 50%;
  height: 500px;
}

.CollectionSearchResult,
.CollectionAlternativeResult {
  outline: none;
  height: 120px;
  overflow: hidden;
  cursor: pointer;
  padding: 0 20px;
}

.CollectionSearchResult .RoomCard,
.CollectionSearchResult .EquipmentCard {
  min-height: 0;
}

.CollectionSearchResult .RoomCard:hover,
.CollectionAlternativeResult .RoomCard:hover,
.CollectionSearchResult .EquipmentCard:hover {
  background-color: var(--color-horizon);
}

.BookingCollectionDesktop__Content .CollectionEmptyState {
  margin: 0 20px;
}

.CollectionSearchResult > div {
  height: 100%;
}

.CollectionSearchResult > div {
  border-bottom: 1px solid var(--color-gray-20);
}

.CollectionSearchResult__Arrow {
  position: relative;
  z-index: 1;
}

.CollectionSearchResult__Arrow:before {
  left: calc(100% - 27px);
  top: calc(50% - 8px);
  font-family: Segoe UI Symbol;
  font-size: 34px;
  content: '◀';
  color: var(--color-white);
  position: absolute;
  text-shadow: 0px 0 5px var(--color-gray-40);
  pointer-events: none;
  z-index: 1;
}

.CollectionEmptyState {
  height: 150px;
  color: var(--color-int-orange);
  border-bottom: 1px solid var(--color-gray-20);
}

.CollectionSearchResults__LoadMore {
  height: 60px;
  margin: 0 20px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid var(--color-gray-20);
}

.CollectionSearchResults__Loader--framed {
  margin: 0 20px;
  border-bottom: 1px solid var(--color-gray-20);
}

.CollectionSearchResultsInlineLoader {
  height: 60px;
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.CollectionSearchResultsInlineLoader__Loader {
  margin: 0 10px;
}

.BookingCollectionDesktop__Results .AlternativeSearchResultsAccordion__Head {
  width: auto;
  margin: 0 20px;
}
