.HelpPage {
  padding-top: 100px;
  width: 100%;
  padding-bottom: 59px;
}

.HelpPage b {
  font-weight: 500;
}

.HelpPage__Divider {
  border: 0;
  border-bottom: 1px solid var(--color-gray-20);
  width: 100%;
  margin: 40px 0;
}

.HelpPageSection {
  width: 80%;
  margin: 0 auto;
}

.HelpPageSection__Title {
  font-weight: 500;
  margin-bottom: 20px !important;
}

.HelpPageSection__Content {
  margin-bottom: 20px;
}

.HelpPageSection__Content p {
  margin-bottom: 10px;
}

@media only screen and (min-width: 900px) {
  .HelpPageSection {
    width: 60%;
  }
}
