.MyBookings__Results {
  padding-bottom: 59px;
}

.MyBookings__Results:focus {
  outline: none;
}

.MyBookings__Results .MyBooking {
  border-bottom: 1px solid var(--color-gray-20);
}

.MyBooking:hover {
  background-color: var(--color-horizon);
}

.MyBookingsDate,
.MyBookingsMonth {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: var(--color-horizon);
  border-bottom: 1px solid var(--color-gray-20);
  font-weight: 500;
  height: 60px;
}
@media only screen and (min-width: 900px) {
  .MyBookingsDate,
  .MyBookingsMonth {
    background-color: var(--color-white);
  }
}

.MyBookingsDesktopWrapper {
  margin: 0 auto;
  margin-top: 60px;
  max-width: 900px;
}

.MyBookings__EmptyState,
.MyBookings__Loader {
  height: calc(100vh - 120px);
}

.MyBookings__Loader {
  position: absolute;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MyBookings__EmptyStateText {
  margin-top: 4px;
}

.MyBookings__EmptyStateButton {
  margin-top: var(--baseline-2);
}

.MyBookings__LoadNextMonth {
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.MyBookingsInlineLoader {
  height: 60px;
  display: flex;
  align-items: center;
}

.MyBookingsInlineLoader__Loader {
  margin: 0 10px;
}
