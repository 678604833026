.Feedback__Title {
  letter-spacing: -0.75px;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

.FeedbackIcon__Icon {
  line-height: 40px;
  font-size: 32px;
  width: 40px;
}

.FeedbackIcon__Icon--happy {
  color: var(--color-int-green--active);
}

.FeedbackIcon__Icon--sad {
  color: var(--color-int-red--active);
}

.Feedback__Group .input-field {
  width: 100%;
}

.Feedback__SubmitButton {
  text-align: center;
  float: right;
}

.Feedback__Content {
  padding-bottom: 60px;
}

.Feedback__Content--desktop {
  padding-top: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.Feedback--succeeded {
  margin-top: -150px;
  transition: margin-top 1s ease-in;
}

.Feedback__InfoMessage {
  width: 600px;
}

.Feedback__InfoText,
.Feedback__NoChangeRequests {
  margin-bottom: 20px;
}

.Feedback__InfoText {
  font-weight: 400;
}

.Feedback__NoChangeRequests {
  display: flex;
  color: var(--color-sc-gray-mid);
}

.FeedbackNoChangeRequests__Icon {
  font-size: 24px;
  width: 40px;
  min-width: 35px;
  margin-top: 3px;
  margin-left: 5px;
}

.Feedback__Group {
  display: flex;
}

.Feedback__Section {
  margin-bottom: 20px;
}

.Feedback__ValidationError {
  margin-top: 10px;
  margin-left: 40px;
}

@media screen and (max-device-height: 675px) {
  .Feedback--succeeded {
    margin-top: -75px;
  }
}

@media only screen and (min-width: 900px) {
  .Feedback {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .Feedback__NoChangeRequests {
    display: none;
  }
}
