.SelectBox {
  background-color: var(--color-white);
  cursor: pointer;
  width: calc(50% - var(--baseline) / 2);
}

@media screen and (max-width: 600px) {
  .SelectBox:not(.SelectBox--quartered):not(.SelectBox--halved) {
    width: 100%;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 600px) {
  .TypeBoxes .SelectBox:nth-child(2n + 1),
  .EquipmentBoxes .SelectBox:nth-child(2n + 1),
  .RoomSizeBoxes .SizeBox:nth-child(2n + 1) {
    margin-right: var(--baseline);
  }

  .TypeBoxes .SelectBox:nth-child(n + 3),
  .EquipmentBoxes .SelectBox:nth-child(n + 3),
  .RoomSizeBoxes .SizeBox:nth-child(n + 3) {
    margin-top: var(--baseline);
  }
}

.SelectBox--selected {
  color: var(--color-white);
  background-color: var(--color-gray-light);
}

.SelectBox--halved {
  width: calc(50% - var(--baseline) / 2);
  margin-bottom: var(--baseline);
}

.SelectBox--quartered {
  width: 25%;
}

.SelectBox--quartered:not(:last-child) {
  margin-right: var(--baseline);
}

.SelectBox--halved:nth-child(odd) {
  margin-right: 8px;
}
