.FormField {
  display: flex;
  align-items: center;
}

.FormField--wrap {
  flex-wrap: wrap;
}

.FormField__Label {
  flex: 1 0 80px;
  max-width: 120px;
  margin-right: 5px;
}

.FormField__Element {
  flex: 1 1 120px;
}

.FormField__Element--truncate {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
