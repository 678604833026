.building {
    padding-top: 80px;
    padding-bottom: 20px;
}

.submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    margin-top: 40px;
}

.read_only_block {
    padding-bottom: 20px;
}

.block {
    width: 700px;
    margin: 0 auto;
}

.edit_block {
    padding-top: 20px;
    margin-bottom: 20px;
    min-height: 60px;
}

.EditResource__Divider {
    border: 0;
    background-color: var(--color-gray-20);;
    height: 1px;
}