.PermissionsForm__ValidationError {
  color: var(--color-input-invalid-text);
  margin-top: -8px;
  margin-bottom: var(--baseline-2);
}

.PermissionsForm__Submit {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 100px);
  margin-top: var(--baseline-4);
  margin-bottom: 60px;
}
