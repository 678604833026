.SearchLocation {
  display: flex;
  outline: none;
}

.SearchLocation__Icon {
  margin-top: 2px;
  margin-left: -2px;
  font-size: 34px;
  min-width: 50px;
  max-width: 50px;
}

@media only screen and (max-width: 600px) {
  .SearchLocation .LocationPicker__Input {
    width: calc(100vw - 90px);
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .SearchLocation .LocationPicker__Input {
    width: 450px;
  }
}
