.StartupLoader__Wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  z-index: 10000;
  transition: background 1s;
}

.StartupLoader--fadeout {
  background: rgba(255, 255, 255, 0);
}

.StartupLoader {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50%;
  height: 100px;
  text-align: center;
}

.StartupLoader > .Loader--large {
  margin: 0 auto;
}

.StartupLoader__Text {
  margin-top: 15px;
  font-size: 26px;
}
