.CalendarRoom {
  display: flex;
  align-items: center;
  height: 69px;
  width: 300px;
  position: relative;
}

.Timeline--compact .CalendarRoom {
  height: 34px;
}

.CalendarRoom:after {
  content: '';
  height: 67px;
  width: 5px;
  position: absolute;
  left: -4px;
  top: 1px;
}

.Timeline--compact .CalendarRoom:after {
  height: 32px;
}

.CalendarRoom--disabled {
  background-color: var(--color-horizon);
}

.CalendarRoom__Info {
  width: 194px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.CalendarRoom__Info span {
  display: block;
  width: 100%;
  padding-right: 10px;
  line-height: 24px !important;
}

.CalendarRoom__Name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
}

.Timeline--compact .CalendarRoom__Building {
  display: none;
}

.CalendarRoom__Favorite {
  height: 20px;
  line-height: 20px;
}

.CalendarRoom__Capacity {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 82px;
}

.CalenderRoom__CapacityIcon {
  font-size: 26px;
  margin-left: 10px;
  margin-right: 15px;
}

.CalendarRoom--unspecified.CalendarRoom:after {
  background-color: var(--color-gray-20);
}

.CalendarRoom--meeting.CalendarRoom:after {
  background-color: var(--colors-room-meeting);
}

.CalendarRoom--standup.CalendarRoom:after {
  background-color: var(--colors-room-standup);
}

.CalendarRoom--conference.CalendarRoom:after {
  background-color: var(--colors-room-conference);
}

.CalendarRoom--project.CalendarRoom:after {
  background-color: var(--colors-room-project);
}

.CalendarRoom--hybridmeeting.CalendarRoom:after {
  background-color: var(--colors-room-hybridmeeting);
}

.CalendarRoom--training.CalendarRoom:after {
  background-color: var(--colors-room-training);
}

.CalendarRoom--workshop.CalendarRoom:after {
  background-color: var(--colors-room-workshop);
}

.CalendarRoom--auditorium.CalendarRoom:after {
  background-color: var(--colors-room-auditorium);
}

.CalendarRoomTooltip {
  max-width: 300px;
  line-height: normal !important;
  white-space: normal !important;
}
