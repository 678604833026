.SearchFormDate {
  padding-bottom: 59px;
}

.SearchFormDate__Section {
  padding: 20px;
  border-bottom: 1px solid #d6d6d6;
}

.SearchFormDate__Content {
  max-width: 500px;
  margin: 0 auto;
}
