.BookingCard__Tray {
  margin-top: 10px;
}

.BookingCard__Information {
  width: 100% !important;
}

.MyBookingsDate,
.MyBooking {
  max-width: 1400px;
}

.MyBooking {
  outline: none;
}
