.RecurrencePatternModalDesktop {
  width: 900px;
  overflow-y: auto;
  margin: 0 auto;
  padding-top: 20px;
}

.RecurrencePatternModalMobile {
  background-color: var(--color-white);
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.RecurrencePatternModalMobile__Content {
  height: calc(100% - 60px);
  margin-top: 60px;
  padding-top: 20px;
  padding-bottom: 60px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.RecurrencePatternModal__Button {
  float: right;
  margin: 20px;
}
