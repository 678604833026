.box {
  height: 60px;
  width: 60px;
  text-align: center;
  transition: background-color 0.3s ease-out;
  will-change: background-color;
  position: relative;
}

.box:focus {
  outline: none;
}

.box:hover,
.active {
  background-color: var(--color-horizon);
}

.box::after {
  bottom: 0px;
  content: '';
  display: block;
  height: 60px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 0px;
  transition: width 0.3s ease-out;
  will-change: width;
}

.active::after {
  background-color: rgb(255, 139, 46);
  width: 6px;
}

.icon {
  font-size: 28px;
  line-height: 60px;
}

.badge {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 13px;
  right: 11px;
  line-height: 16px;
  background-color: var(--color-int-orange);
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  user-select: none;
}
