.SearchRecurrence {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.SearchRecurrence--deactivated {
  opacity: 0.5;
  cursor: not-allowed;
}

.SearchRecurrence__Icon {
  margin-left: -2px;
  font-size: 34px;
  max-width: 50px;
  min-width: 50px;
}

.SearchRecurrence_Text {
  width: 100%;
  line-height: 20px;
}

.SearchRecurrence_Title {
  font-weight: 600;
}

.SearchRecurrence__RedirectIcon {
  font-size: 34px;
  margin-right: -7px;
}
