.RecurrenceExceptionModalDesktop .AlternativeSearchResults__Indentation {
  padding: 0 20px;
}

.RecurrenceExceptionModalDesktop .AlternativeEmptyState {
  border-bottom: none;
}

.RecurrenceExceptionModalDesktop
  .AlternativeSearchResultsAccordion
  .AlternativeSearchResultsAccordion__Head {
  border-top: 1px solid var(--color-gray-20);
}

.RecurrenceExceptionModalDesktop
  .AlternativeSearchResultsAccordion
  + .AlternativeSearchResultsAccordion
  .AlternativeSearchResultsAccordion__Head {
  border-top: none;
}

.AlternativeSearchResults--desktop {
  width: 50%;
  height: 500px;
}

.AlternativeSearchResult,
.BookAlternativeResult {
  outline: none;
  overflow: hidden;
  padding: 0 20px;
}

.BookAlternativeResult {
  height: 154px;
}

.AlternativeSearchResult > div {
  height: 100%;
}

.AlternativeSearchResult > div {
  border-bottom: 1px solid var(--color-gray-20);
}

.BookAlternativeResult .RoomCard {
  justify-content: center;
}

.RecurrenceExceptionModalDesktop__Results .RoomCard:hover {
  background-color: var(--color-horizon);
}

.AlternativeSearchResult__Arrow {
  position: relative;
  z-index: 1;
}

.AlternativeSearchResult__Arrow:before {
  left: calc(100% - 27px);
  top: calc(50% - 8px);
  font-family: Segoe UI Symbol;
  font-size: 34px;
  content: '◀';
  color: var(--color-white);
  position: absolute;
  text-shadow: 0px 0 5px var(--color-gray-40);
  pointer-events: none;
  z-index: 1;
}

.AlternativeEmptyState {
  height: 150px;
  color: var(--color-int-orange);
  border-bottom: 1px solid var(--color-gray-20);
}

.AlternativeSearchResults__LoadMore {
  height: 60px;
  margin: 0 20px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.AlternativeSearchResultsInlineLoader {
  height: 60px;
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.AlternativeSearchResultsInlineLoader__Loader {
  margin: 0 10px;
}
