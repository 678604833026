.ResourceDetail {
  width: 700px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 20px;
}

.ResourceDetail__ResourceDeleted {
  display: flex;
  color: var(--color-sc-gray-mid);
}

.ResourceDetailResourceDeleted__Icon {
  margin-top: 1px;
  font-size: 20px;
  width: 30px;
}
