button,
.button {
  cursor: pointer;
}

.button {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: relative;
  transition: all 70ms var(--button-easing);
  margin: 0;
  outline: none;
  border: 0;
  border-bottom-style: none;
  border-radius: var(--button-border-radius);
  background: none;
  cursor: pointer;
  padding: var(--button-padding-vertical) var(--button-padding-horizontal);
  min-width: 160px;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 0;
  transform-origin: 50% 50%;
  user-select: none;
}

.button:active {
  transform: scale(0.98);
}

.button.disabled,
.button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.button,
.button--primary {
  border: 0;
  border-bottom-style: none;
  border-color: var(--color-int-blue);
  background-color: var(--color-int-blue);
  color: var(--color-white);
}

.button:hover,
.button:focus,
.button:active,
.button--primary:hover,
.button--primary:focus,
.button--primary:active {
  border: 0;
  border-bottom-style: none;
  border-color: var(--color-int-blue--active);
  background-color: var(--color-int-blue--active);
  color: var(--color-white);
}

.button.disabled,
.button:disabled,
.button--primary.disabled,
.button--primary:disabled {
  border-color: var(--color-button-primary--disabled);
  background-color: var(--color-button-primary--disabled);
  color: var(--color-button-primary--disabled-text);
}

.button--secondary {
  border: 0;
  border-bottom-style: none;
  border-color: var(--color-button-secondary);
  background-color: var(--color-button-secondary);
  color: var(--color-gray);
}

.button--secondary:hover,
.button--secondary:focus,
.button--secondary:active {
  border: 0;
  border-bottom-style: none;
  border-color: var(--color-button-secondary--active);
  background-color: var(--color-button-secondary--active);
  color: var(--color-gray);
}

.button--secondary.disabled,
.button--secondary:disabled {
  border-color: var(--color-button-secondary--disabled);
  background-color: var(--color-button-secondary--disabled);
  color: var(--color-button-secondary--disabled-text);
}

.button--confirm {
  border: 0;
  border-bottom-style: none;
  border-color: var(--color-int-green);
  background-color: var(--color-int-green);
  color: var(--color-white);
}

.button--confirm:hover,
.button--confirm:focus,
.button--confirm:active {
  border: 0;
  border-bottom-style: none;
  border-color: var(--color-int-green--active);
  background-color: var(--color-int-green--active);
  color: var(--color-white);
}

.button--confirm.disabled,
.button--confirm:disabled {
  border-color: var(--color-button-confirm--disabled);
  background-color: var(--color-button-confirm--disabled);
  color: var(--color-button-primary--disabled-text);
}

.button--outlined,
.button--outlined:hover,
.button--outlined:focus,
.button--outlined:active {
  flex-shrink: 0;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-20);
  color: var(--color-gray);
  padding: 7px 20px;
}

.button--outlined.button__icon {
  display: flex;
  align-items: center;
  padding: 7px 20px;
}

.button__icon {
  padding: 12px 24px 12px 16px;
}

.button .icon {
  margin-right: 7px;
  font-size: 16px;
}

.button--thin {
  padding: 11px 23px;
}

.button--thin,
.button--thin.button--primary {
  border: 1px solid var(--color-int-blue);
  background-color: transparent;
  color: var(--color-white);
}

.button--thin:hover,
.button--thin:focus,
.button--thin:active,
.button--thin.button--primary:hover,
.button--thin.button--primary:focus,
.button--thin.button--primary:active {
  border-color: var(--color-int-blue--active);
  background-color: transparent;
  color: var(--color-white);
}

.button--thin.disabled,
.button--thin:disabled,
.button--thin.button--primary.disabled,
.button--thin.button--primary:disabled {
  border-color: var(--color-button-primary--disabled);
  background-color: transparent;
  color: rgba(255, 255, 255, 0.4);
}

.button--thin.button--secondary {
  border: 1px solid var(--color-int-gray);
  background-color: transparent;
  color: var(--color-white);
}

.button--thin.button--secondary:hover,
.button--thin.button--secondary:focus,
.button--thin.button--secondary:active {
  border-color: var(--color-int-gray--active);
  background-color: transparent;
  color: var(--color-white);
}

.button--thin.button--secondary.disabled,
.button--thin.button--secondary:disabled {
  border-color: var(--color-button-secondary--disabled);
  background-color: transparent;
  color: rgba(255, 255, 255, 0.4);
}

.button--thin.button--confirm {
  border: 1px solid var(--color-int-green);
  background-color: transparent;
  color: var(--color-white);
}

.button--thin.button--confirm:hover,
.button--thin.button--confirm:focus,
.button--thin.button--confirm:active {
  border-color: var(--color-int-green--active);
  background-color: transparent;
  color: var(--color-white);
}

.button--thin.button--confirm.disabled,
.button--thin.button--confirm:disabled {
  border-color: var(--color-button-confirm--disabled);
  background-color: transparent;
  color: rgba(255, 255, 255, 0.4);
}

.button--full,
input[type='submit'].button--full,
input[type='reset'].button--full,
input[type='button'].button--full {
  display: block;
  width: 100%;
}

.button--fixed:active,
.button--full:active,
input[type='submit'].button--full:active,
input[type='reset'].button--full:active,
input[type='button'].button--full:active,
input[type='submit'].button--fixed:active,
input[type='reset'].button--fixed:active,
input[type='button'].button--fixed:active {
  transform: none;
}

.button--fixed,
input[type='submit'].button--fixed,
input[type='reset'].button--fixed,
input[type='button'].button--fixed {
  position: fixed;
  display: block;
  width: 100%;
  height: 60px;
  left: 0;
  bottom: 0;
  text-align: center;
  border-radius: 0;
  z-index: 500;
}

.button--fixed.button--full {
  width: calc(100% - 40px);
  left: 20px;
  bottom: 20px;
}

.button--responsive,
input[type='submit'].button--responsive,
input[type='reset'].button--responsive,
input[type='button'].button--responsive {
  width: 100%;
}
@media (min-width: 480px) {
  .button--responsive,
  input[type='submit'].button--responsive,
  input[type='reset'].button--responsive,
  input[type='button'].button--responsive {
    width: auto;
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
}

.button-group--left {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.button-group--right {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (min-width: 480px) {
  .button-group--fill {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.button-group--fill .button {
  width: 100%;
  min-width: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.button-group .button {
  margin-right: 10px;
}

.button-group + .button-group {
  margin-top: 8px;
}

.button--loading > div > div {
  margin: 0 auto;
}
