.DataField {
    display: flex;
    height: 40px;
    justify-content: center;
  }
  
  .DataField__Label {
    font-weight: 500;
    max-width: 200px;
    margin-right: 5px;
    flex: 1 0 120px;
  }
  
  .DataField__Value {
    flex: 1 1 120px;
  }