.BuildingResponsibility__Loader {
  max-width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  padding: 20px 20px 20px;
}

.buildings {
  padding: 100px;
}

.search {
  align-items: center;
  padding-left: 20px;
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1em;
  width: 30%;
  font-weight: 400;
}

.pages {
  text-align: center;
}

.page_number {
  padding-top: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.current_page_number {
  color: var(--color-link);
}