.BookingCollectionSidebar {
  position: relative;
  height: 100%;
}

.BookingCollectionSidebar__Detail {
  padding: var(--baseline-2) 20px;
}

.BookingCollectionSidebar__Shadow::before,
.BookingCollectionSidebar__Shadow::after {
  display: block;
  content: '';
  position: absolute;
  width: 5px;
  height: 100%;
  top: 0px;
}

.BookingCollectionSidebar__Shadow--left::before {
  left: -5px;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(173, 173, 173, .4));
}

.BookingCollectionSidebar .DataQualityInformation {
  display: none;
}

.BookingCollectionSidebar__Book {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.BookingCollectionSidebar__Date {
  border-bottom: 1px solid var(--color-gray-20);
  padding: 20px;
  display: flex;
}

.BookingCollectionSidebar__DateLabel {
  font-weight: 500;
  width: 120px;
}

.BookingCollectionDivider {
  background-color: var(--color-gray-20);
  height: 1px;
  width: 100%;
  margin: 20px 0;
}

.BookingCollectionNotAcceptedInfo {
  margin: 0 20px var(--baseline-2) 20px;
}

.BookingCollectionNotAcceptedInfo__Icon {
  margin-right: 5px;
}
