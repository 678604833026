.list li {
  margin-left: 14px;
}

.list li::before {
  margin-left: -17px;
  font-family: sdx-icons;
  font-size: 16px;
  content: '';
  color: var(--color-blue);
}
