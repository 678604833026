.FlyoutButton__Wrapper {
  position: absolute;
  background-color: var(--color-white);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.FlyoutButton__Wrapper--mobile {
  width: 100%;
  text-align: center;
}

.FlyoutButton__Wrapper--desktop {
  width: 250px;
  left: 50%;
  margin-left: -115px;
}

.FlyoutButton__Seperator {
  height: 1px;
  background-color: var(--color-button-secondary--active);
}

.FlyoutButton__Option {
  height: 60px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;

  font-weight: 500;
  font-size: 16px;
}

.FlyoutButton__Option:hover {
  background-color: var(--color-horizon);
}
