.CalendarExport {
  margin-left: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
}

.link.CalendarExportIcon,
.CalendarExportIcon {
  color: var(--color-gray);
  float: right;
  margin-right: 8px;
  border-bottom: none;
  text-align: center;
}

.link.CalendarExportIcon:hover,
.CalendarExportIcon:hover {
  border-bottom: none;
}

.CalendarExportIcon__Label {
  line-height: 11px;
  font-size: 11px !important;
  font-weight: 500;
  letter-spacing: 0;
}

.CalendarExportIcon__Icon {
  font-size: 26px;
  line-height: 26px;
  top: 4px;
  right: 2px;
}
