.dateBody {
  padding: 10px 5px;
}

.dateHeading {
  margin-bottom: 4px;
  max-width: 100%;
  color: var(--color-black);
  font-weight: 500;
  font-size: 1rem;
}

.datePicker {
  width: 300px;
}

.requiredText {
  font-size: 13px;
  color: var(--color-int-orange);
}

.input {
  padding-bottom: 5px;
}

.link {
  font-size: 13px !important;
}