.Hint__Information {
    margin-bottom: var(--baseline-2);
    width: 100%;
    color: var(--color-sc-gray-mid);
    display: flex;
  }
  
.Hint__Information-Icon {
width: 28px;
min-width: 28px;
margin-top: 4px;
}

.Hint__Information-Warning {
color: var(--color-sc-red);
}