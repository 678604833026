.CalendarEquipment {
  display: flex;
  align-items: center;
  height: 70px;
  width: 300px;
  position: relative;
}

.Timeline--compact .CalendarEquipment {
  height: 35px;
}

.CalendarEquipment:after {
  content: '';
  height: 67px;
  width: 5px;
  position: absolute;
  left: -4px;
  top: 1px;
}

.Timeline--compact .CalendarEquipment:after {
  height: 32px;
}

.CalendarEquipment--disabled {
  background-color: var(--color-horizon);
}

.CalendarEquipment__Info {
  width: 200px;
  margin-left: 15px;
  flex: auto;
}

.CalendarEquipment__Info span {
  display: block;
  width: 100%;
  padding-right: 10px;
  line-height: 24px !important;
}

.CalendarEquipment__Name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
}

.CalendarEquipment__Favorite {
  height: 20px;
  line-height: 20px;
  padding-right: var(--baseline-2);
}

.Timeline--compact .CalendarEquipment__Building {
  display: none;
}

.CalendarEquipment__Info .CalendarEquipment__Name {
  font-weight: 500;
}

.CalendarEquipment--unknown.CalendarEquipment:after {
  background-color: var(--color-gray-20);
}

.CalendarEquipment--laptop.CalendarEquipment:after {
  background-color: var(--colors-equipment-laptop);
}

.CalendarEquipment--ucc.CalendarEquipment:after {
  background-color: var(--colors-equipment-ucc);
}

.CalendarEquipment--beamer.CalendarEquipment:after {
  background-color: var(--colors-equipment-beamer);
}

.CalendarEquipment--whiteboard.CalendarEquipment:after {
  background-color: var(--colors-equipment-whiteboard);
}

.CalendarEquipment--flipchart.CalendarEquipment:after {
  background-color: var(--colors-equipment-flipchart);
}

.CalendarEquipment--presenter.CalendarEquipment:after {
  background-color: var(--colors-equipment-presenter);
}

.CalendarEquipment--planner.CalendarEquipment:after {
  background-color: var(--colors-equipment-planner);
}

.CalendarEquipment--audio.CalendarEquipment:after {
  background-color: var(--colors-equipment-audio);
}

.CalendarEquipment--car.CalendarEquipment:after {
  background-color: var(--colors-equipment-car);
}

.CalendarEquipment--parking.CalendarEquipment:after {
  background-color: var(--colors-equipment-parking);
}

.CalendarEquipmentTooltip {
  max-width: 300px;
  line-height: normal !important;
  white-space: normal !important;
  padding: 10px 15px;
}

.CalendarEquipmentTooltip__Header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.CalendarEquipmentTooltip__Location {
  flex: auto;
  margin-right: 10px;
  width: 100%;
}

.CalendarEquipmentTooltip__Name {
  white-space: nowrap;
  font-weight: 500;
}

.CalendarEquipmentTooltip__ManagedBy {
  display: flex;
}

.CalendarEquipmentTooltip__Footer {
  margin-top: var(--baseline-2);
  margin-bottom: -8px;
  margin-left: -20px;
  padding: 8px 20px;
  width: calc(100% + 40px);
  border-top: 1px solid var(--color-gray-20);
  background-color: var(--color-horizon);
}

.EquipmentTooltip.tooltip {
  padding: 0 !important;
}
